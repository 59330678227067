import React from 'react';

function AnneeEnCours() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      {currentYear}
    </>
  );
}

export default AnneeEnCours;