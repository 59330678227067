import React, {useState, useEffect} from "react";
import axios from "axios";
import styled from "styled-components";
import { useSelector, useDispatch } from 'react-redux';
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import PopErrorST from "../PopupsST/PopError";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import FormLocalisationST from "../FormsST/FormLocalisation";
import { AiOutlineCloseCircle,AiOutlineCheckCircle } from "react-icons/ai";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import PointsRelaisListeST from "./PointsRelaisListe";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import FormDateHeureST from "../FormsST/FormDateHeure";
import { FaPen } from "react-icons/fa";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
padding: 20px;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }

  &>.date {
    &>svg {
    cursor: pointer;
    color: ${props => props.theme.orangeST};
    margin-left: 10px;
    }
  }
`;

const Checks = styled.div`
display: flex;
flex-wrap: wrap;
gap: 20px;
`;

const Link = styled.button`
border: none;
background: none;
color: ${props => props.theme.orangeST};
font-size: 20px;
text-decoration: underline;
text-align: left;
padding: 20px 0;
&:hover {
    color: ${props => props.theme.greenST};
}
`
const Title = styled.div`
color: ${props => props.theme.orangeST};
font-size: 20px;
display: flex;  
gap: 10px;
justify-content: center;
align-items: center;
`

const Mode = styled.div`
color: ${props => props.theme.grey02ST};
font-size: 20px;
`

const Message = styled.div`
position: relative;
padding: 20px;
margin: 35px 0;
border-radius: 5px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
&>p {
  margin: 0;
  &>svg {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    &.red {
      color: #f61400;
    }
  }
}
`;

export default function LivraisonST({className,onNotInStockChange,atelierOnly}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user?.user?.Contact); 
  const mag = useSelector(state => state.magasin?.magasin);
  const basket = useSelector(state => state.basket);
  const relais = useSelector(state => state.relais?.relais);
  const modeL = useSelector(state => state.modeLivr?.modeLivraison);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [selectedOption, setSelectedOption] = useState(modeL);
  const [showMode, setShowMode] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRelais, setIsLoadingRelais] = useState(false);
  const [nomMag, setNomMag] = useState(mag ? mag.Nom : "WEB");
  const [idMag, setIdMag] = useState(mag ? mag.uiD : "50ec9764-679e-4acf-8945-61a97ffcb8d5");
  const [modeLivr, setModeLivr] = useState(modeL === 1 ? "Livraison à domicile" : modeL === 2 ? "Retrait en magasin "+mag.Nom : modeL === 3 ? "Livraison en point relais" : "Livraison à domicile");
  const [stock, setStock] = useState([]);
  const [blockSteps, setBlockSteps] = useState(false);
  const [pointsR, setPointsR] = useState([]);
  const [searchData, setSearchData] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const date = useSelector(state => state?.date?.date);
  const heure = useSelector(state => state?.heure?.heure);
  const [showForm , setShowForm] = useState(false);

  useEffect(() => {
    switch (modeL) {
      case 1: setModeLivr("Livraison à domicile");
      break;
      case 2: 
      if (mag?.ID === "50ec9764-679e-4acf-8945-61a97ffcb8d5") { //WEB
        setModeLivr("Livraison à domicile");
      } else { 
      setModeLivr("Retrait en magasin "+mag.Nom); 
    }
      break;
      case 3: setModeLivr("Livraison en point relais");
      break;
      default: setModeLivr("Livraison à domicile");
    }
  }, [modeL, mag]);

  const handleSearch = (data) => {
    const { name, postcode, city } = data;
    if (name || postcode || city) {
      const formattedAddress = `${name || ''}, ${postcode || ''}, ${city || ''}`;
      setSearchData(formattedAddress);
    } else {
      setSearchData(null);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("Selected Date:", date);
  };

  const handleSelectedPoint = (point) => {
    setSelectedPoint(point);
    dispatch({
      type: "SET_ADRESSE",
      payload: {
        IDAdresse: point.identifiant,
      }
    });
  };

  const newSearch = (value) => {
    console.log("Search Value111:", value);
    const today = new Date().toISOString().split('T')[0];
    setIsLoadingRelais(true);
    axios.post(`/users/pointlivraisonliste`, { Adresse: value, DateExpedition: selectedDate || "" })
      .then((response) => {
        setPointsR(response?.data?.PointLivraisonListe?.Result?.listePointRetraitAcheminement);
        setIsLoadingRelais(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoadingRelais(false);
        setIsErrorVisible(true);
        setTxt(err?.PointLivraisonListe?.Erreur ? err?.PointLivraisonListe?.Erreur : "Aucun point relais n'a été trouvé pour votre recherche");
      });
  }; 
  
  const newSearchByContact = () => {
    console.log("Search Value222:", user);
    const today = new Date().toISOString().split('T')[0];
    setIsLoadingRelais(true);
    axios.post(`/users/pointlivraisonliste`, { Adresse: user?.Adresse1+","+user?.CP+","+user?.Ville,DateExpedition: selectedDate || "" })
      .then((response) => {
        setPointsR(response?.data?.PointLivraisonListe?.Result?.listePointRetraitAcheminement);
        setIsLoadingRelais(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoadingRelais(false);
        setIsErrorVisible(true);
        setTxt(err?.PointLivraisonListe?.Erreur ? err?.PointLivraisonListe?.Erreur : "Aucun point relais n'a été trouvé pour votre recherche");
      });
  };

  useEffect(() => {
    if (selectedOption === 3) {
   
    if (searchData) {
      newSearch(searchData);
    } else {  
      newSearchByContact();
    }
  }
  }, [searchData,selectedDate,selectedOption]);

  const handleOptionChange = (e) => {
    const value = Number(e.target.value);
    setSelectedOption(value);

  if (value === 1) {
    setModeLivr("Livraison à domicile");
    dispatch({
      type: "SET_MODELIVR",
      payload: {
        modeLivraison: 1
      }
    });

    dispatch({
      type: "DELETE_ADRESSE",
    });

    dispatch({
      type: "DELETE_RELAIS",
    });
    dispatch({
        type: "SET_MAGASIN",
        payload: {
            ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
            Nom: "WEB"
        }
    });
    // setTxt("Votre choix a bien été pris en compte");
    // setIsErrorVisible(true);
  };

  if (value === 2) {
    setModeLivr("Retrait en magasin");

    dispatch({
      type: "SET_MODELIVR",
      payload: {
        modeLivraison: 2
      }
    });

    dispatch({
      type: "DELETE_RELAIS",
    });

    dispatch({
      type: "DELETE_ADRESSE",
    });

    dispatch({
      type: "SET_MAGASIN",
      payload: {
          ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
          Nom: "WEB"
      }
  });
    
    };
    
  if (value === 3) {
    setModeLivr("Livraison en point relais");
    setIsLoadingRelais(true);
    newSearchByContact();
    dispatch({
      type: "SET_MODELIVR",
      payload: {
        modeLivraison: 3
      }
    });

    dispatch({
      type: "DELETE_ADRESSE",
    });

    dispatch({
      type: "SET_MAGASIN",
      payload: {
          ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
          Nom: "WEB"
      }
  });
  }
};


 
  
  const verifStock = () => {
    setIsLoading(true);
    let results = [];
    const itemsToCheck = basket && basket.items.filter(item => item.mode === "article");
    if (itemsToCheck.length === 0) {
      setIsLoading(false);
      return;
    }
    itemsToCheck.forEach(item => {
      axios.post(`/users/stock`, { IDArticle: item.ID, IDMagasin: idMag })
        .then(response => {
          // console.log("response", response.data);
          // Handle the response for each item
          const stockInfo = { id: item.ID, stock: response.data?.EnStock };
          results.push(stockInfo);
          setStock([...results]);
          setIsLoading(false);
        })
        .catch(error => {
          // Handle errors for each item
          console.error('Error verifying stock:', error);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const inStock = stock.every(item => item?.stock > 0);
console.log("inStock", inStock);
  useEffect(() => {
    setBlockSteps(!inStock);
    onNotInStockChange(!inStock);
  }, [inStock, onNotInStockChange]);

function formatDate(dateString) {
  const [year, month, day] = dateString?.split('-');
  return `${day}/${month}/${year}`;
}
    return (
        <Wrap className={className}>
          {isLoading && <SpinnerInlineST/>}
          {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"Livraison"} onClick={() => setIsErrorVisible(false)}/>}
          <h2 className="center homemadeTitle initial green" id="mode">Votre mode de livraison</h2>
          {atelierOnly && <Message><p className='orange bold center'>Livraison par la voie éléctronique</p></Message>}  
          {!atelierOnly && <>
          <Title>Livraison choisie : <Mode>{modeLivr}</Mode></Title>
          {modeL === 2 && mag?.ID !== "50ec9764-679e-4acf-8945-61a97ffcb8d5" && (date && heure) && (
          <p className="date">Le {formatDate(date?.date)} à {heure?.heure} <FaPen onClick={() => setShowForm(!showForm)} /></p>)
          }
          {(showForm || (modeL === 2 && mag?.ID !== "50ec9764-679e-4acf-8945-61a97ffcb8d5" && (!date || !heure))) && (
          <FormDateHeureST />
          )}
          {/* {isLoading ? <Message><SpinnerInlineST/></Message> : <Message>
            {inStock ? (
              <p className='orange bold center'><AiOutlineCheckCircle className="green"/>Bonne nouvelle ! Tous les articles de votre panier sont en stock :)</p>
             ) : (
              <p className='orange bold center'><AiOutlineCloseCircle className="red"/>Malheureusement, certains articles de votre panier ne sont pas en stock. Veuillez vérifier les quantités ou les retirer de votre panier.<br></br>
              <LienNoirST lib="Voir mon panier" href="/panier" />
              </p>
             )}
          </Message>  } */}
          {!isLoading && <> <Link onClick={() => (setShowMode(!showMode))}>Changer de mode de livraison</Link>
                    {showMode && <><Checks>
                      <FormGroup check>
                        <InputStyled
                            name="livraison"
                            type="radio"
                            value={1}
                            checked={selectedOption === 1}
                            onChange={handleOptionChange}
                        />
                        <Label check>
                        Livraison à domicile
                        </Label>
                        </FormGroup>

                        <FormGroup check>
                        <InputStyled
                            name="retrait"
                            type="radio"
                            value={2}
                            checked={selectedOption === 2}
                            onChange={handleOptionChange}
                        />
                        <Label check>
                            Retrait en magasin
                        </Label>
                        </FormGroup>
                        
                        {/* <FormGroup check>
                        <InputStyled
                            name="relais"
                            type="radio"
                            value={3}
                            checked={selectedOption === 3}
                            onChange={handleOptionChange}
                        />
                        <Label check>
                        Livraison en point relais
                        </Label>
                        </FormGroup> */}
                    </Checks>
                    {selectedOption === 2 && <FormLocalisationST mode2={"panier"} panier={basket}/>}
                    {selectedOption === 3 && <PointsRelaisListeST pointsR={pointsR} onPointSelect={handleSelectedPoint} isLoading={isLoadingRelais} onSearch={handleSearch} onDateChange={handleDateChange}/>}
                    {/* {searchData && <div>Search Data: {JSON.stringify(searchData)}</div>} */}
                    </>
                    }
            </>}
            </>}
        </Wrap>
  );
}
