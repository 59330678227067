import React, {useState, useEffect, lazy, Suspense} from 'react';
import { FaShareAlt } from "react-icons/fa";
import { RWebShare } from "react-web-share";
import {FadeStyled } from "../DesignSystem/Commons/Containers";
import { IoMdShareAlt } from "react-icons/io";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import styled from "styled-components";

const ContainerShare = styled.div`
position: absolute;
z-index: 10;
top: 30px;
right: -100px;
display: flex;
justify-content: flex-end;
flex-direction: column;
gap: 10px;
padding: 10px;
&.static {
    position: static;
}
@media screen and (max-width: 786px) {
  top: 40px;
  right: -13px;
}
`

const Icons = styled.div`
position: absolute;
top: 40px;
right: 0px;
display: flex;
align-items: center;
justify-content: flex-end;
gap: 10px;
padding: 10px;
&>button {
  transition: all 0.3s;
}
&>button:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
`
const Btn = styled.button`
background-color: transparent;
z-index: 100;
position: absolute;
border: none;
padding: 10px;
border-radius: 5px;
color: ${props => props.theme.beigeTresClair};
&:hover {
    color: ${props => props.theme.beigeClair};
}
`
const Btn2 = styled.button`
background-color: transparent;
border: none;
z-index: 2;
display: flex;
align-items: center;
gap: 5px;
color: ${props => props.theme.greenST};
&>svg {
  height: 26px;
  width: 27px;
}
&:hover {
    color: ${props => props.theme.grey09ST};
}
`

export default function ShareST({shareUrl, title, className, media}) {
  // const body = `${title}\n${media ? `\n${media}` : ""}`;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setOpen(false);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

    return ( <>
     {/* <div>
      <RWebShare
        data={{
          text: "Texte de partage",
          url: shareUrl,
          title: title,
        }}
        onClick={() => console.log("shared successfully!")}
      >
        <Btn>Partager <FaShareAlt/></Btn>
      </RWebShare>
    </div> */}
   
<ContainerShare className={className}>
{/* <div>
      <RWebShare
        data={{
          text: "Like humans, flamingos make friends for life",
          url: shareUrl,
          title: "Flamingos",
        }}
        onClick={() => console.log("shared successfully!")}
      >
        <button>Share 🔗</button>
      </RWebShare>
    </div> */}
    <Btn2 onClick={() => setOpen(!open)} title="Partager"><IoMdShareAlt/></Btn2>
       {open && <Icons>
    
    <FacebookShareButton 
    url={shareUrl} 
    quote="Découvrez ce contenu incroyable !"
    hashtag="#selftissus"
    className="socialMediaShareButton"
    >
    <FacebookIcon size={30} round={false} borderRadius={5} />
    </FacebookShareButton>

    <TwitterShareButton
    url={shareUrl}
    title={title}
    hashtags={["selftissus"]}
    className="socialMediaShareButton"
    >
    <XIcon size={30} round={false} borderRadius={5} />
    </TwitterShareButton>

    <LinkedinShareButton
    url={shareUrl}
    source={shareUrl}
    title={title}
    summary=""
    className="socialMediaShareButton"
    >
    <LinkedinIcon size={30} round={false} borderRadius={5} />
    </LinkedinShareButton>

    <PinterestShareButton
    url={shareUrl}
    description={title}
    media={media ? media : shareUrl}
    pinId={""}
    className="socialMediaShareButton"
    >
    <PinterestIcon size={30} round={false} borderRadius={5} />
    </PinterestShareButton>

    <EmailShareButton
    url={shareUrl}
    title={title}
    subject="Partage SELF TISSUS"
    body={title}
    separator={shareUrl ? "\n\n" : ""}
    className="socialMediaShareButton"
    >
    <EmailIcon size={30} round={false} borderRadius={5}/>
    </EmailShareButton>
</Icons>}

</ContainerShare>
</>
    )
}   