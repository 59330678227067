import React, { useState } from "react";
import styled from "styled-components";
import FormNewsletterFooterST from "../FormsST/FormNewsletterFooter";

const Wrapper = styled.div`
 border: 1px solid ${props => props.theme.grey07ST};
background: #fff;
padding: 50px 0px;
display: flex;
flex-direction: row;
gap: 50px;
align-items: center;
justify-content: center;
flex-shrink: 0;
width: 100%;
position: relative;
margin: 0;
@media only screen and (max-width: 992px) {
  gap: 100px;
}
`;

const StyledDiv_2 = styled.div`
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    align-items: center;
  }
`;

export default function Footer1ST() {

  return (
      <Wrapper>
        
            <FormNewsletterFooterST />
     
      </Wrapper>
  );
}
