// Import any necessary action types
// import { SET_USER, UPDATE_USER, DELETE_USER } from '../actions/types';

// Define the initial state
const initialState = {
    items: [],
    totalItems: 0,
};

// Create the reducer function
const basketReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TO_BASKET":
          const newItemsAdd = [...state.items, action.payload];
            return {
                ...state,
                items: newItemsAdd,
                totalItems: newItemsAdd.length,
            };
            case 'UPDATE_BASKET':
              const newItemsUpdate = state.items.map(item =>
               item.planifId  ? item.ID === action.payload.id && item.planifId === action.payload.planifId ? { ...item, quantity: action.payload.quantity } : item :  item.ID === action.payload.id  
                      ? { ...item, quantity: action.payload.quantity }
                      : item  
              );
              return {
                  ...state,
                  items: newItemsUpdate,
                  totalItems: newItemsUpdate.length,
              };
            case "DELETE_ARTICLE":
              const newItemsDelete = state.items.filter((item) => !item.planifId  ?  item.ID !== action.payload.id :  item.planifId !== action.payload.planifId); 
              return {
                  ...state,
                  items: newItemsDelete,
                  totalItems: newItemsDelete.length,
              };
        
            case "DELETE_BASKET":
              return {
                ...state,
                items: [],
                totalItems: 0,
              };
            default:
                return state;
    }
};

export default basketReducer;