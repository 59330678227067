import React, { useEffect,useState } from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import styled from "styled-components";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import PopErrorST from "../PopupsST/PopError";
import BoutonTransparentST from "../DesignSystem/SelfTissus/BoutonTransparent";
import BoutonTransparentBtnST from "../DesignSystem/SelfTissus/BoutonTransparentBtn";
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import FormRealisationST from "../FormsST/FormRealisation";
import {WrapFormPop} from "../DesignSystem/PopUpST";
import picDefault from "../../assets/images/photo.jpg";
import SpinnerInlineMiniST from "../DesignSystem/SpinnersST/SpinnerInlineMini";

const Wrap = styled.div`
width: 100%;
column-count: 3;
gap: 50px;
columns: 300px;
`;

const Real = styled.div`
position: relative;
width: 100%;
box-shadow: 0 0 10px #f05b3270;
transition: box-shadow 0.3s;
&> img {
  width: 100%;
	height: fit-content;
}

&> span {
  display: block;
  margin: 10px 0;
}

&:hover {
  box-shadow: 0 0 10px #f05b32;
}
`;

const OverlayPop = styled.div`
background: rgba(0, 0, 0, 0.75);
display: block;
position: fixed;
top: 0;
/* left: 50%; */
height: 100%;
width: 100%;
z-index: 999;
    `;

const WrapImg = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%;
`;

const Img = styled.img`
max-width: 90%;
max-height: 90%;
`;

const Close = styled.div`
text-align: center;
position: absolute;
top: 30px;
right: 30px;
color: ${props => props.theme.orangeST};
font-size: 30px;
cursor: pointer;
&:hover {
    opacity: 0.8;
}   
`;

const Author = styled.span`
position: absolute;
bottom: 0;
right: 0;
background: ${props => props.theme.grey06ST};
width: auto;
padding: 0 20px;
color: #fff;
margin: 0 !important;
`;



export default function TutosListeST({data}) {
const [realisations, setRealisations] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const [isLoadingPhoto, setIsLoadingPhoto] = useState(true);
const [isErrorVisible, setIsErrorVisible] = useState(false);
const [txt, setTxt] = useState("");
const [realisationsNbr, setRealisationsNbr] = useState(0);
const [isModalOpen, setIsModalOpen] = useState(false);
const [modalImage, setModalImage] = useState(null);
const [modalImageTitle, setModalTitle] = useState("");
const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        axios.post("/users/realisationliste", {Type:1, Limite:30 , OFFSET: 0})
            .then((response) => {
                setIsLoading(false);
                console.log("response", response);
                setRealisations(response.data?.Realisations);
                setRealisationsNbr(response.data?.RealisationsNbr); 
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log("err", err);
                setIsLoading(false);
            });
    }
    , []);

    const handleChangeOpen = (value) => {
      setShowForm(value);
    } 

    const handleMoreReals = (currentLength, increment) => {
        setIsLoading(true);
        return axios.post("/users/realisationliste", { Limite: increment, OFFSET: currentLength })
          .then((response) => {
            const newReals = response.data?.Realisations || [];
            setRealisations(prevReals => [...prevReals, ...newReals]);
            setRealisationsNbr(response.data?.RealisationsNbr); 
            setIsLoading(false);
            return newReals;
          })
          .catch((err) => {
            console.log("err", err);
            setTxt("Une erreur est survenue. Veuillez réessayer plus tard.");
            setIsErrorVisible(true);
            setIsLoading(false);
            return [];
          });
      };
    
      const handleMoreRealsClick = async (event, currentLength, increment) => {
        console.log("handleMoreRealsClick", currentLength, increment);

        event.preventDefault();
        await handleMoreReals(currentLength, increment);
      };

      const showImg = (realisation) => {
        setModalImage(realisation?.ImageTitre?.url);
        setModalTitle(realisation?.Titre);
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
        setModalImage(null);
        setModalTitle("");
      };


    return (
        <>
           <h2 className="center initial">{data?.Titre}</h2>
           {/* <BoutonNoirST lib={data?.BTNLib} onClick={() => setShowForm(true)}/> */}
        <Wrap>
            {isLoading && <SpinnerST />}
            {isErrorVisible && <PopErrorST message={txt} titre={"Realisations"} onClick={() => { setIsErrorVisible(false)}} />}
            {realisations.map((realisation) => (
                 <Real key={realisation?.ID}>
                 <span className="center initial orange fs18">{realisation?.Titre}</span>
                 {isLoadingPhoto && <SpinnerInlineMiniST />}
                 <img
                   onError={(e) => {
                   e.target.onerror = null;
                   e.target.src = picDefault;
                  }} 
                  onLoad={() => setIsLoadingPhoto(false)}
                  loading="lazy"
                   className="center"
                   src={realisation?.ImageTitre?.url}
                   alt={realisation?.ImageTitre?.alt ? realisation?.ImageTitre?.alt : realisation?.Titre}
                   onClick={() => showImg(realisation)}
                   style={{ cursor: 'pointer' }}
                 />
                 <Author className="right initial fs15">{realisation?.Auteur}</Author>
               </Real>
            ))}
            
           
            
        </Wrap>
         {realisations.length < realisationsNbr ? (
            <BoutonTransparentBtnST
            onClick={(event) => handleMoreRealsClick(event, realisations.length, 5)}
            lib="Voir plus de tutos"
            />
        ) : null}
         {isModalOpen && ReactDOM.createPortal(
        <OverlayPop onClick={closeModal}>
          <WrapImg>
            <Close onClick={closeModal}>X</Close>
            <p className="center initial white fs23">{modalImageTitle}</p>
            <Img src={modalImage} alt={modalImageTitle}/>
          </WrapImg>
        </OverlayPop>,
        document.getElementById('root')
      )}

      {/* {showForm  &&
       ReactDOM.createPortal(
      <WrapFormPop className="form"><FormRealisationST className="npPop" onChangeOpen={handleChangeOpen}/></WrapFormPop>,
            document.getElementById('root')
      ) } */}
        </>
    )
}
