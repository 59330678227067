import React, {useState,useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FloatButton } from "antd";
import { MdMenu } from "react-icons/md";
import styled, {keyframes} from 'styled-components';
import { AiOutlineArrowUp } from "react-icons/ai";
import DrawerElems from './DrawerElemMobST';
import FormNewsletterST from '../FormsST/FormNewsletter';
import img from "../../assets/images/newsletter.png";

const shine = keyframes`
  0% {
    background-position: -200px;
  }
  100% {
    background-position: 200px;
  }
`;

const FloatButtonGroupStyled  = styled(FloatButton.Group)`
right: 50px;
 bottom: 70px;
 &>button {  
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @media only screen and (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
  &>.ant-float-btn-body {
    background: linear-gradient(90deg, ${props => props.theme.jaune01ST} 25%, ${props => props.theme.greenST}  50%, ${props => props.theme.jaune01ST} 75%);
    background-size: 200px 100%;
    animation: ${shine} 10s infinite linear;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 0.8;
      transform: scale(1.1);
    }
    background-color: ${props => props.theme.jaune01ST};
    &>.ant-float-btn-content > .ant-float-btn-icon {
      width: 60px !important;
      @media only screen and (max-width: 768px) {
        width: 40px !important;
      }
    }
  }
  &:hover {
    transform: scale(1.1);
  }
 }
`
// export const ShiningButton = styled.button`
//   background: linear-gradient(90deg, #f0f0f0 25%, #ffffff 50%, #f0f0f0 75%);
//   background-size: 200px 100%;
//   animation: ${shine} 2s infinite linear;
//   border: none;
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   transform: scale(1.1);

//   &:hover {
//     transform: scale(1.1);
//   }
// `;

const FloatButtonGroupStyled2  = styled(FloatButton.Group)`
left: 20px;
bottom: 70px;
&>button {
  box-shadow: 0px 2px 2px rgba(0,0,0,0.25);
  transition: all 0.2s ease-in-out;
  &>.ant-float-btn-body {
    transition: all 0.2s ease-in-out;
    background-color: ${props => props.theme.redST};
    &:hover {
      opacity: 0.8;
      transform: scale(1.1);
      background-color: ${props => props.theme.redST};
    }
    &>.ant-float-btn-content>.ant-float-btn-icon  {
    width: unset !important;
    font-size: unset !important;
    }
    &>div>.ant-float-btn-icon > svg {
    color: #fff;
    font-size: 25px;
    }
  }
  
}
`

const ButtonsVolants_ST = ({showBtnMenu, data}) => {
const [visible, setVisible] = useState(false);
const [show, setShow] = useState(false);
const [showNewsletter, setShowNewsletter] = useState(false);
const user = useSelector(state => state?.user?.user?.Contact);
  const toggleDrawer = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (user && !user?.Newsletter) {
      setShowNewsletter(true);
    } else {
      setShowNewsletter(false);
    }
  }
  , [user]);

    return (
      <>
        {showNewsletter && (
        <FloatButtonGroupStyled shape="circle">
          <FloatButton
            id="txt"
            icon={<img src={img} alt="Newsletter" style={{ width: '100%', height: '100%' }} />}
            title="Newsletter"
            onClick={() => {
              setShow(true);
            }}
          />
          {/* <ShiningButton
            id="txt"
            onClick={() => {
              setShow(true);
            }}
          >
            <IoNewspaper />Newsletter
          </ShiningButton> */}
        </FloatButtonGroupStyled>
      )}

    {/* <DrawerMenu dataMenu={dataMenu} visible={visible} onClose={toggleDrawer} /> */}
     <FloatButtonGroupStyled2 shape="circle">
     {showBtnMenu && <FloatButton
        icon = {<MdMenu  />}
        onClick={toggleDrawer}
      />}
      <FloatButton.BackTop 
      icon={<AiOutlineArrowUp />}
      visibilityHeight={100} />
    </FloatButtonGroupStyled2>
    {/* <DrawerMenu dataMenu={dataMenu} onClose={toggleDrawer} /> */}
    <DrawerElems visible={visible} onClose={toggleDrawer} data={data} elem='menu'/>
    {/* <DrawerInfoST visible={show} onClose={() => setShow(false)} /> */}
    <FormNewsletterST visible={show} onClose={() => setShow(false)} />
  </> 
       
    );
};

export default ButtonsVolants_ST;