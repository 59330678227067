import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'; // Import Leaflet
import { FaMapMarkerAlt } from "react-icons/fa";
import ReactDOMServer from 'react-dom/server'; 
import BoutonNoirLnkST from "../DesignSystem/SelfTissus/BoutonNoirLnk";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import malo from "../../assets/images/mag/22.jpg"
import img1 from "../../assets/images/mag/6.jpg"
import img2 from "../../assets/images/mag/9.jpg"
import img3 from "../../assets/images/mag/25.jpg"
import img4 from "../../assets/images/mag/37.jpg"

const StyledWrapper= styled.div`
position: relative;
width: 100%;
height:100%;
margin: 20px auto;
padding: 20px;
display: flex;  
flex-direction: column;
justify-content: center;
align-items: center;
gap: 50px;
@media only screen and (max-width: 768px) {
  height: auto;
  gap: 30px;
  &>h2 {
    text-align: center;
  }
}
`

const StyledMapContainer = styled(MapContainer)`
  width: 100% !important;
  height: 600px;
`;

const Keen = styled.div`
border-radius: 5px; 
/* max-width: 350px !important; */

`

const Item = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 30px;
&>img {
  width: 360px;
  height: auto;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.5);
  @media only screen and (max-width: 768px) {
  width: 100%;
}
}
@media only screen and (max-width: 1250px) {
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 768px) {
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
`;

const Det = styled.div`
display: flex;
flex-direction: column;
align-items: center;
&>a.info {
  min-width: unset;
  padding: 10px;
  margin: 0;
  width: max-content;
}
`;

const Buts = styled.div`  
display: flex;
flex-wrap: wrap;
gap: 50px;
`;


const customIcon = L.divIcon({
  html: ReactDOMServer.renderToString(<FaMapMarkerAlt style={{ fontSize: '30px', color: 'red' }} />),
  className: 'custom-div-icon'
});


export default function MagasinFicheST({data}) {
  console.log("mFiche",data)
  const [coordinates, setCoordinates] = useState([47.9812583, -4.0395185]);
  useEffect(() => {
    const query = `${data?.Adresse1}, ${data?.CP} ${data?.Ville}, ${data?.Pays}`;
    axios
      .get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=1`)
      .then((response) => {
        console.log("API response:", response); // Log the entire response
        if (response.data.features.length > 0) {
          console.log("Search results:", response.data.features); // Log results for debugging
          const result = response.data.features[0];
          console.log("Search result:", result); // Log result for debugging
          const coords = result.geometry.coordinates || [0, 0];
          setCoordinates([coords[1], coords[0]]);
          console.log("City coordinates:", coords);
        } else {
          console.error("No results found for the given search term.");
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error);
      });
  }, [data]);
  
  function generateGoogleMapsLink(position) {
    const [lat, lng] = position;
    return `https://www.google.com/maps/dir//${lat}+${lng}/@${lat},${lng},12z/data=!4m6!4m5!1m0!1m3!2m2!1d${lng}!2d${lat}?entry=ttu`;
  }
  const centerPosition = [47.9812583, -4.0395185];
  const markerPositions = [
    { position: coordinates, name: data?.NomCommercial, icon: customIcon, img: data?.TabImage[0]?.url },
  ].map(marker => ({
    ...marker,
    link: generateGoogleMapsLink(marker.position)
  }));

  const mapRef = useRef();

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize();
    }
  }, []);

  const galleryItems = data?.TabImage.map(image => ({
    original: image.url,
    thumbnail: image.url,
    originalAlt: image.alt
  }));

  return (
    <div  style={{ width: '100%', height: '100%' }}>
    <StyledWrapper>
      <h2>{data?.NomCommercial}</h2>
      
      {galleryItems?.length>0 ? <ImageGallery 
      items={galleryItems} 
      thumbnailPosition="bottom" 
      showPlayButton={false} 
      showFullscreenButton={false} 
      showIndex={false} 
      slideInterval={5000}
      showThumbnails={true}
      additionalClass="mag"
    /> : null}
        <Det>
          <p>{data?.Adresse1}<br></br>
          {data?.Adresse2}<br></br>
          {data?.CP} {data?.Ville}</p>
        </Det>
      <Buts>
        <BoutonNoirLnkST href={`mailto:${data?.Mail}`} lib="Ecrivez-nous"/>
        <BoutonNoirLnkST className={"info"} href={`tel:${data?.Tel}`} lib="Appelez-nous"/>
      </Buts>
        <div>
          <p className="center homemadeTitle initial orange fs20">{data?.Accueil}</p>
          <p>Horaires d'ouverture:<br></br>
          {data?.Horaires}</p>
        </div>
          <StyledMapContainer center={centerPosition} zoom={7} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markerPositions.map((marker, idx) => (
              <Marker key={idx} position={marker.position} icon={marker.icon}>
              <Popup>
                <img src={marker.img} alt={marker.name} style={{ width: '100px', height: 'auto' }} />
                <br />
                <FaMapMarkerAlt /> {marker.name} <br /> 
                <a href={marker.link} target="_blank" rel="noopener noreferrer">Itinéraire vers ce magasin</a>
              </Popup>
            </Marker>
            ))}
          </StyledMapContainer>
          {/* <h2 className="center homemadeTitle initial green">Les services proposés en magasin</h2> */}
    </StyledWrapper>
    </div>
  );
}

