import React, {useState} from "react";
import styled from 'styled-components';
import { FaRegClock } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import imgBlogDefault from "../../images/blog.webp"
import ReactHtmlParser from 'react-html-parser';
import { Section, WrapperBlogs } from "../DesignSystem/SelfTissus/ContainersST";

import { Card } from 'antd';
const { Meta } = Card;

const CardStyled = styled(Card)`
  cursor: default;
  `;

const Infos = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 10px 0 20px 0;
  &>span {
    color :${props => props.theme.grey09ST};
    font-size: 11px;
  }
  `;

const Title = styled.h2`
margin: 0 0 20px 0;
font-size: 29px;
`


export default function BlogListeST({data}) {
  console.log("data1", data);
function FormateDate(date){
  if(date !== undefined && date !== null && date !== ""){
    const dateObj = new Date(date);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return dateObj.toLocaleDateString('fr-FR', options);
  }
  return "";
}

  return (
      <Section className="noPadding">
        {data?.Titre && data?.Titre !=="WebComposant" ? <h2 className="center">{data?.Titre}</h2> : null}
         <WrapperBlogs>
          {
            data && data?.Data?.sort((a, b) => a.Tri - b.Tri).map((item, i) => {
              return (
                <CardStyled
                  key={i}
                  hoverable
                  className="cardBlog"
                  cover={<img alt="image de blog Self Tissus" src={item?.image?.url ? item?.image?.url : imgBlogDefault} 
                  // onClick={() => window.location.href = item?.BTNUrl}
                  />}
                >
                  {/* <Infos>
                    <span>{item?.autor +" - "}</span> 
                    <span>{FormateDate(item?.date) +" - "}</span> 
                    <span>{item?.time +" minutes "}</span>
                  </Infos> */}
                  <Title className="blog grey">{item.Titre}</Title>
                  <p className="center">{item.Corps}</p>
                  <a href={item?.BTNUrl} target={item?.BTNMode ? "_blank" : "_self"}  onClick={() => console.log('BTNMode:', item?.BTNMode)}>{item?.BTNLib}</a>
                </CardStyled>
              );
            })
          }
            
        </WrapperBlogs>
        {/* {isLoading && <SpinnerHP />}  */}
        
      </Section>
  );
}