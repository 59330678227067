import React, { useEffect, useState, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import PresentationTitreST from "../../components/ContenusST/PresentationTitre";
import TextST from "../../components/ContenusST/Text";
import imgDefault from "../../assets/images/photo.jpg";
import ReactHtmlParser from 'react-html-parser';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const Wrap = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
gap: 30px;
justify-content: flex-start;
padding-bottom: 20px;
&>div {
  /* background: -webkit-linear-gradient(#0d5e3f, #0aaa6e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: italic;
  font-size: 18px; */
  flex-basis: 65%;
  margin: 0;
    @media screen and (max-width: 992px) {
        flex-basis: 100%;
    }
}
@media screen and (max-width: 786px) {
    flex-wrap: wrap;
    gap: 50px;
}
`;

const Img = styled.img`
float: left;
/* flex-basis: 48%; */
max-width: 350px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
@media screen and (max-width: 786px) {
    flex-basis: 100%;
    max-width: 100%;
}
`

const ProjetImg = styled.img`
width: 100px;
height: 100px;
object-fit: cover;
box-shadow: 0 0 10px 0 ${props => props.theme.redST};
border-radius: 50%;
position: absolute;
right: 10px;
top: 45px;
@media screen and (max-width: 786px) {
    width: 70px;
    height: 70px;
}
`

export default function CategorieIntroST({data}) {
  return (
    <>
    {data?.imageLogo && <ProjetImg src={data?.imageLogo?.url} alt="picto de projet" />}
    <PresentationTitreST titre={data?.lib}/>
    {data?.Descriptif && <Wrap>
        <Img src={data?.image ? data?.image?.url : imgDefault} alt="image de catégorie" />
        {/* {data?.Descriptif && <ReactMarkdown>{desc[0]}</ReactMarkdown>} */}
        {/* {data?.Descriptif && <ReactMarkdown>{data?.Descriptif}</ReactMarkdown>} */}
        {data?.Descriptif && (
        <ReactMarkdown
          children={data?.Descriptif}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
        />
      )}
    </Wrap>}
    </>
   
  );
}


