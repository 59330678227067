import React , { useState, useEffect,useRef } from 'react'
import { Router } from '@reach/router';
import styled from 'styled-components';
import ArianeST from './ArianeST/Ariane';
import HeaderLayout from '../styles/HeaderLayout'
import NavBar from './NavBar/NavBarSelf';
import BandeauInfoSliderSelf from './BandeauInfo/BandeauInfoSliderSelf';
import axios from 'axios';
import ButtonsVolants_ST from './NavBar/ButtonsVolantsST';


const StickyHeader = styled(HeaderLayout)`
@media (min-width: 1200px) {
opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
`;

const Header = ({ showBtnMenu,scrollValue }) => {

const [dataMenuHaut, setDataMenuHaut] = useState([]);
const [isVisible, setIsVisible] = useState(true);
const lastScrollTop = useRef(0);
const navbarHeight = 0;
const [dataBandeau2, setDataBandeau2] = useState([]);
const [dataBandeau3, setDataBandeau3] = useState([]);
const [isLoading, setIsLoading] = useState(true);


useEffect(() => {
 
  const handleScrollFilter = () => {
    let st = window.scrollY || document.documentElement.scrollTop;
      if (st  > lastScrollTop.current && st > navbarHeight) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      lastScrollTop.current = st <=   navbarHeight? 0 : st;
    }
  window.addEventListener("scroll", handleScrollFilter);

  return () => {
    window.removeEventListener("scroll", handleScrollFilter);
  };
   
}, []);

useEffect(() => {
  axios.get(`/menuhaut?{"CleCtrl":"TaTaYoYo","act":"MenuHaut"}`).then(res => {
 
    setDataMenuHaut(res.data?.MenuHaut ? res.data.MenuHaut : []);
  }
  ).catch(err => {
    console.log(err);
  }
  );
}
,[]);

useEffect(() => {
  setIsLoading(true);
  axios.post(`/users/bandeau`, { Limite: "", OFFSET: "", NumBandeau:4})
    .then((response) => {
      setDataBandeau2(response?.data);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
}, []);

useEffect(() => {
  setIsLoading(true);
  axios.post(`/users/bandeau`, { Limite: "", OFFSET: "", NumBandeau:3})
    .then((response) => {
      setDataBandeau3(response?.data);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
}, []);

return (
  
<>
 <StickyHeader isVisible={isVisible}>
 <BandeauInfoSliderSelf data={dataBandeau2}/>
  <NavBar dataMenuHaut={dataMenuHaut}/>
  {/* <BandeauInfoSelf/> */}
  <BandeauInfoSliderSelf data={dataBandeau3}/>
</StickyHeader> 
        
<ButtonsVolants_ST showBtnMenu={showBtnMenu} scrollValue={scrollValue} data={dataMenuHaut}/>
{/* <Router>
    <ArianeST default/>
</Router> */}
</>


)
  }
export default Header
