import React, {useState} from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import  {Label} from "reactstrap";
import { FormConnect,InputStyled } from "../DesignSystem/SelfTissus/Forms";

const Wrap = styled.div`
width: 100%;
`

export default function FormDateHeureST() {
    const today = new Date().toISOString().split('T')[0]; 
    const dispatch = useDispatch();
    const date = useSelector(state => state?.date?.date);
    const heure = useSelector(state => state?.heure?.heure);
    const [txt1, setTxt1] = useState("");
    const [txt2, setTxt2] = useState("");

    const handleDateChange = (e) => {
      const selectedDate = e.target.value;
      const selectedDateObj = new Date(selectedDate);
      const dayOfWeek = selectedDateObj.getUTCDay(); // 0 is Sunday, 6 is Saturday

      if (selectedDate < today) {
        setTxt1("La date doit être supérieure ou égale à la date d'aujourd'hui");
        e.target.value = "jj/mm/aaaa";
      } else if (dayOfWeek === 0) { // If the selected date is Sunday
        setTxt1("Les dimanches ne sont pas autorisés. Veuillez sélectionner une autre date.");
        e.target.value = "jj/mm/aaaa";
      } else {
        dispatch({
          type: "SET_DATE",
          payload: {
            date: selectedDate,
          },
        });
        setTxt1(''); // Clear any previous error message
      }
    };

    const handleHourChange = (e) => {
        const selectedHour = e.target.value;
        console.log("selectedHour", selectedHour);
    
        // Validate the selected hour
        if (selectedHour !== "0" && selectedHour !== "") {
          dispatch({
            type: "SET_HEURE",
            payload: {
              heure: selectedHour,
            },
          });
          setTxt2(''); // Clear any previous error message
        } else {
          setTxt2("L'heure est obligatoire");
          dispatch({
            type: "DELETE_HEURE",
            });
        }
      };

    return (
        <Wrap>
        <p className="orange center">Choisissez une date et une heure pour retirer votre commande</p>
        <FormConnect floating>
            <InputStyled type="date" min={today} id="date" name="date" 
            value={date?.date}
            onChange={handleDateChange}
            />
            <Label for="date">Date</Label>
            <p className="red">{txt1}</p>
          </FormConnect>

          <FormConnect floating>
            <InputStyled type="select" id="heure" name="heure" 
            value={heure?.heure}
            onChange={handleHourChange}
            >
            <option value={0}>Choisir une heure</option>
            <option value={"10:00"}>10:00</option>
            <option value={"11:00"}>11:00</option>
            <option value={"14:00"}>14:00</option>
            <option value={"15:00"}>15:00</option>
            <option value={"16:00"}>16:00</option>
            <option value={"17:00"}>17:00</option>
            <option value={"18:00"}>18:00</option>
            </InputStyled>
            <Label for="heure">Heure</Label>
            <p className="red">{txt2}</p>
          </FormConnect>
          </Wrap>
    )
}
