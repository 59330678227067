import styled from 'styled-components';
import { 
    TabContent, TabPane, Nav, 
    NavItem, NavLink, Row, Col 
  } from 'reactstrap'; 

  export const NavLinkStyled = styled(NavLink)`
  font-size: 20px;
  border: none !important;
  color: ${props => props.theme.grey03ST} !important;
  background-color: transparent !important;
  &.active {
  color: ${props => props.theme.greenST} !important;
  border-bottom: 2px solid ${props => props.theme.greenST} !important;
  }
  &:hover>div {
  text-decoration: none;
  color: ${props => props.theme.greenST};
  }
  `;
  
  export const NavItemStyled = styled(NavItem)`
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  
  `;

