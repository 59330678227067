import React, {useState, useEffect} from "react";
import axios from "axios";
import styled from "styled-components";
import { useSelector, useDispatch } from 'react-redux';
import { Descriptions } from 'antd';
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import FormAdresseST from "../FormsST/FormAdresse";
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import {FadeStyled } from "../DesignSystem/Commons/Containers";
import  {FormGroup ,Label} from "reactstrap";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import FormAdresseLivraisonST from "../FormsST/FormAdresseLivraison";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
padding: 20px;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }
`;

const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`

const DescriptionsStyled = styled(Descriptions)`
width: 100%;
font-family: 'Assistant', sans-serif;
&>.ant-descriptions-view  {
}
&>.ant-descriptions-view > table > tbody > tr> th {
  background-color: #f05b320a !important;
  font-size: 17px !important;
}
&>.ant-descriptions-view > table > tbody > tr> td {
  font-size: 16px !important;
}
`;

const Mag = styled.div`
position: relative;
`;



const Buts = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: flex-start;
gap: 20px;
&.center {
  justify-content: center;
}
&>button {
  margin : 0;
}
@media only screen and (max-width: 786px) {
  flex-direction: column;
  gap: 10px;
}
`;

const Message = styled.div`
background: ${props => props.theme.orangeST};
padding: 20px;
margin: 35px 0;
border-radius: 5px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
&>p {
  margin: 0;
}
`;

const New = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

const Adrs = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
gap: 50px;
margin-bottom: 50px;
`;

const ButsAdr = styled.div`
display: flex;
align-items: flex-start;
justify-content: center;
gap: 20px;
&>button {
  margin : 0;
}
`;

const Adr = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
border: 1px solid ${props => props.theme.grey01ST};
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
padding: 50px;
gap: 10px;
&>p {
  margin: 0;
}
`;



export default function AdresseST({className,atelierOnly,setBlockIfWrorgAdresse}) {
  const user = useSelector(state => state.user?.user?.Contact); 
  const mag = useSelector(state => state.magasin?.magasin);
  const adresse = useSelector(state => state.adresse?.adresse);
  const relais = useSelector(state => state.relais?.relais);
  const modeL = useSelector(state => state.modeLivr?.modeLivraison);
  const [idMag, setIdMag] = useState(mag?.ID || "50ec9764-679e-4acf-8945-61a97ffcb8d5");
  const [showForm , setShowForm] = useState(false);
  const [magasin, setMagasin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [open, setOpen] = useState(false);
  const [switched, setSwitched] = useState(false);
  const [chosenAddress, setChosenAddress] = useState(null);
  const dispatch = useDispatch();
  const [showFormIndex, setShowFormIndex] = useState(null);
  const [blockStep, setBlockStep] = useState(false);
  const [blockIfNoDateHeure, setBlockIfNoDateHeure] = useState(false);
  const date = useSelector(state => state?.date?.date);
  const heure = useSelector(state => state?.heure?.heure);

  function formatDate(dateString) {
    if (!dateString) return "";
    const [year, month, day] = dateString?.split('-');
    return `${day}/${month}/${year}`;
  }

  const [form, setForm] = useState({
    Destinataire: "",
    Adresse: "",
    CP: "",
    Ville: "",
  });

  // useEffect(() => {
  //   if(atelierOnly) {
  //     dispatch({
  //       type: "SET_ADRESSE",
  //       payload: {
  //         IDAdresse: user?.TabAdresse[0]?.ID,
  //       }
  //     });

  //     dispatch({
  //       type: "MODELIVR",
  //       payload: {
  //         modeLivraison: 0,
  //       }
  //     });
  //   }
  // }, [atelierOnly]);

  console.log("chosenAddress", chosenAddress);
  const handleClose = () => {
    setShowFormIndex(null);
  };


  useEffect(() => {
    if (idMag === "50ec9764-679e-4acf-8945-61a97ffcb8d5") {
      return;
    }
    setIsLoading(true);
    axios.post(`/users/magasinliste`,{Limite : 20,OFFSET : "" })
    .then((response) => {
      const filteredMagasin = response?.data?.Magasin.find(magasin => magasin.uiD == idMag);
      setMagasin(filteredMagasin);
      console.log("magasin", filteredMagasin);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
  }, []);

  const newAdresse = () => {
    console.log("newAdresse", form);
    setIsLoading(true);
    axios.post(`/users/membresauveadresselivraison`, form)
      .then((response) => {
        console.log("response", response);
        setIsLoading(false);
        setIsErrorVisible(true);
        setTxt("Votre adresse a bien été ajoutée");
        dispatch({
          type: "UPDATE_USER",
          payload: {
            Contact: {
              ...user,
              TabAdresse: [...user?.TabAdresse, form],
            },
          },
        });
        setOpen(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        setIsErrorVisible(true);
        setTxt("Une erreur s'est produite, veuillez réessayer");
        dispatch({type: "DELETE_USER"});
      });
  };
  useEffect(() => {
    const isMagIDInvalid = mag?.ID === null || mag?.ID === "50ec9764-679e-4acf-8945-61a97ffcb8d5";
    if (modeL === 1 && isMagIDInvalid && !adresse) { // à domicile & magasin web
      console.log(2, modeL === 2 && isMagIDInvalid && !adresse);
      setBlockIfWrorgAdresse(true);
      setBlockStep(true);
    } else if (modeL === 2 && isMagIDInvalid) { // en magasin & magasin web
      console.log(3, modeL === 2 && isMagIDInvalid);
      setBlockIfWrorgAdresse(true);
      setBlockStep(true);
    } else if (modeL === 3 && !relais) { // en point relais & pas de relais
      console.log(4, modeL === 3 && !relais?.relais);
      setBlockIfWrorgAdresse(true);
      setBlockStep(true);
    } else if (modeL === 2 && (!date || (!heure || heure?.heure === "0"))) { // c&c without date
      console.log(5, modeL === 2 && !date || (!heure || heure?.heure === "0"));
      console.log("modeL", blockStep);
      setBlockIfNoDateHeure(true);
      setBlockStep(true);
      console.log("blockStep2", blockStep);
    } else {
      console.log(6, "aure chose");
      setBlockIfWrorgAdresse(false);
      setBlockStep(false);
    }
  }, [mag, modeL, adresse, setBlockIfWrorgAdresse, relais, date, heure, blockIfNoDateHeure]);


    return (
        <Wrap className={className}>
           {isLoading && <SpinnerST />}
           {isErrorVisible && <PopErrorST message={txt} titre={"Adresse"} onClick={() => { setIsErrorVisible(false)}} />}
            {!atelierOnly ?<h2 className="center homemadeTitle initial green">Votre adresse de livraison</h2> : <h2 className="center homemadeTitle initial green">Votre adresse de facturation</h2>}
            {((modeL === 1) && mag?.ID === "50ec9764-679e-4acf-8945-61a97ffcb8d5") ? (
            !switched ? (
              <>
              <Adrs>
                {user?.TabAdresse?.map((adr, index) => (
                <Adr key={index}>
                  {showFormIndex !== index && (
                    <>
                      <p>{adr?.Destinataire}</p>
                      <p>{adr?.Adresse}</p>
                      <p>{adr?.CP} {adr?.Ville}</p>
                      <ButsAdr>
                      <BoutonST background={"#ffce00"} lib={"Choisir cette adresse"} onClick={() => { 
                          setSwitched(true); 
                          setChosenAddress(adr); 
                          dispatch({
                            type: "SET_ADRESSE",
                            payload: {
                              IDAdresse: adr?.ID,
                            }
                          });
                        }} />
                        <BoutonST background={"#000"} onClick={() => setShowFormIndex(index)} lib={"Mettre à jour"} />
                        {/* <BoutonST background={"#DF5A4E"} onClick={() => setShowFormIndex(index)} lib={"Supprimer"} /> */}
                      </ButsAdr>
                    </>
                  )}
                  {showFormIndex === index && <FormAdresseLivraisonST onClose={handleClose} adr={adr} adrIndex={index} />}
                </Adr>
                ))}
                
              </Adrs>
              <Buts>
                <BoutonST background={"#79b69f"} lib={"Ajouter une autre adresse"}  
                onClick={() => {
                  setOpen(!open);
                  setForm({});
                }}/>
              </Buts>
              
               <FadeStyled className="noPadding" in={open}>
                <Wrap>
                <FormStyled floating>
                  <InputStyled
                    id="Destinataire"
                    name="Destinataire"
                    placeholder="Destinataire"
                    type="text"
                    onChange={(e) => setForm({ ...form, Destinataire: e.target.value })}
                />
                  <Label for="Adresse">Destinataire</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="Adresse"
                    name="Adresse"
                    placeholder="Adresse"
                    type="textarea"
                    onChange={(e) => setForm({ ...form, Adresse: e.target.value })}
                />
                  <Label for="Adresse">Adresse</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="cp"
                    name="CP"
                    placeholder="Code postal"
                    type="text"
                    onChange={(e) => setForm({ ...form, CP: e.target.value })}
                    autoComplete
                />
                  <Label for="cp">Code postal</Label>
                </FormStyled>

                <FormStyled floating>
                  <InputStyled
                    id="Ville"
                    name="Ville"
                    placeholder="Ville"
                    type="text"
                    onChange={(e) => setForm({ ...form, Ville: e.target.value })}
                    autoComplete
                />
                  <Label for="Ville">Ville</Label>
                </FormStyled>
                <Buts className="center">
                  <BoutonST background={"#79b69f"} lib={"Enregistrer"} onClick={() => newAdresse()}/>
                  <BoutonST background={"#000"} lib={"Annuler"} onClick={() => setOpen(false)}/>
                </Buts>
                </Wrap>
              </FadeStyled>
              {blockStep && <Message>
                    <p className='white bold'>Veuillez choisir une adresse de livraison valide pour continuer</p>
                </Message>}
              </> 
              ) : (
            chosenAddress && (
              <div>
                <p>{chosenAddress?.Destinataire}</p>
                <p>{chosenAddress?.Adresse}</p>
                <p>{chosenAddress?.CP} {chosenAddress?.Ville}</p>
                <p>{chosenAddress?.Pays}</p>
                <BoutonST background={"#79b69f"} lib={"Changer l'adresse"} onClick={() => setSwitched(false)} />
              </div>
            )
          )
        ) : (modeL === 2) ? (
          <>
            <Mag>
            {isLoading && <SpinnerInlineST />}
            <p className="center orange bold fs20">Vous avez choisi le retrait en magasin</p>
            <p>{magasin?.Nom}</p>
            <p>{magasin?.Adresse}</p>
            <p>{magasin?.CP} {magasin?.Ville}</p>
            <p>{magasin?.Pays}</p>
            {magasin?.slug && <LienNoirST lib={"Voir les détails du magasin"} href={magasin?.slug} target="_blank"/>}
            {blockStep && blockIfNoDateHeure ? (
                <Message>
                  <p className='white bold'>Veuillez choisir une date et/ou une heure dans l'étape précédente pour continuer</p>
                </Message>
              ) : (
                date && heure && (
                  <>
                    <br />
                    <br />
                    <p>Le {formatDate(date?.date)} à {heure?.heure}</p>
                  </>
                )
              )}
            </Mag>
            {blockStep && mag?.ID === "50ec9764-679e-4acf-8945-61a97ffcb8d5" && <Message>
              <p className='white bold'>Veuillez choisir un magasin dans l'étape précédente pour continuer</p>
            </Message>}
          </>
          ) : (modeL === 3) ? (
            <>
            <Mag>
            {isLoading && <SpinnerInlineST />}
            <p className="center orange bold fs20">Vous avez choisi le retrait en point relais</p>
            <p>{relais?.Nom}</p>
            <p>{relais?.Adresse1}</p>
            <p>{relais?.Adresse2}</p>
            <p>{relais?.Adresse3}</p>
            <p>{relais?.CP} {relais?.Ville}</p>
            </Mag>
            {blockStep && <Message>
              <p className='white bold'>Veuillez choisir un point relais dans l'étape précédente pour continuer</p>
            </Message>}
            </>) : (
              null
            )}
          {/* // ) : (
          //   <Mag>
          //   {isLoading && <SpinnerInlineST />}
          //   <p className="center orange bold fs20">Les articles seront livrés à l'adresse électronique suivante : </p>
          //   <p>{user?.Mail}</p>
          //   </Mag>
          // )} */}

          
        </Wrap>
  );
}
