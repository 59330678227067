import React, {useState, useEffect} from "react";
import axios from "axios";
import styled from "styled-components";
import { Descriptions } from 'antd';
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import {Wrap} from "./ElementsMembre";
import { useSelector } from "react-redux";
import FormAdresseST from "../FormsST/FormAdresse";
import FormModifMdpST from "../FormsST/FormModifMdp";

const DescriptionsStyled = styled(Descriptions)`
width: 100%;
font-family: 'Assistant', sans-serif;
&>.ant-descriptions.ant-descriptions-bordered >.ant-descriptions-view .ant-descriptions-row >.ant-descriptions-item-label:last-child, :where(.css-dev-only-do-not-override-kghr11).ant-descriptions.ant-descriptions-bordered >.ant-descriptions-view .ant-descriptions-row >.ant-descriptions-item-content:last-child {
  border-inline-end: 1px solid #A29995;
}
&>.ant-descriptions-view > table > tbody > tr> th {
  background-color: #f05b320a !important;
  font-size: 17px !important;
}
&>.ant-descriptions-view > table > tbody > tr> td {
  font-size: 16px !important;
}
`;

const Buts = styled.div`
display: flex;
flex-wrap: wrap;
gap: 20px;
`;


export default function MembreInfosST({className}) {
  const [showForm , setShowForm] = useState(false);
  const [showFormMdp , setShowFormMdp] = useState(false);
  const user = useSelector(state => state.user?.user?.Contact); 
 

  const handleClose = () => {
    setShowForm(false);
  }; 
  const handleClose2 = () => {
    setShowFormMdp(false);
  };
  const items = [
    {
      key: '1',
      label: 'Nom',
      children: user?.Nom,
      span: 3,
    },
    {
      key: '2',
      label: 'Prénom',
      children: user?.Prenom,
      span: 3,
    },
    {
      key: '3',
      label: 'Date de naissance',
      children: user?.DateNaissance ||'N/A', // Assuming this is static or needs to be updated
      span: 3,
    },
    {
      key: '4',
      label: 'Email',
      children: user?.Mail || 'N/A', // Default email if empty
      span: 3,
    },
    {
      key: '5',
      label: 'Numéro de téléphone',
      children: user?.Tel,
      span: 3,
    },
    {
      key: '6',
      label: 'Adresse de facturation',
      children: (
        <>
          {user?.Nom} {user?.Prenom}
          {user?.Adresse1 ? <br /> : null}
          {user?.Adresse1} 
          {user?.Adresse2 ? <br /> : null}
          {user?.Adresse2} 
          <br />
          {user?.CP} {user?.Ville}
          <br />
          {user?.Pays}
        </>
      ),
      span: 3,
    },
  ];
    return (
        <Wrap className={className}>
          <h3 className="center homemadeTitle initial orange">Mes informations</h3>
          {!showForm && !showFormMdp && (
            <DescriptionsStyled
              title=""
              bordered
              items={items}
              extra={
                <Buts>
                  <BoutonNoirST onClick={() => setShowFormMdp(true)} lib={"Modifier mon mot de passe"} />
                  <BoutonNoirST onClick={() => setShowForm(true)} lib={"Modifier"} />
                </Buts>
              }
            />
          )}

          {showForm && <FormAdresseST onClose={handleClose} user={user}/>} 
          {showFormMdp && <FormModifMdpST onClose={handleClose2}/> }
        </Wrap>
  );
}
