import React, {useState, useEffect} from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import { useSelector, useDispatch } from "react-redux";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import FormOubliPassST from "./FormOubliPass";
import {PopUpContainer} from "../DesignSystem/PopUpST";
import BoutonNoirBtnST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import BoutonBlancBtnST from "../DesignSystem/SelfTissus/BoutonBlancBtn";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 0;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`

const Buts = styled.div`
display: flex;
gap: 20px;
flex-wrap: wrap;
` 

const Eye = styled.div`
position: absolute;
transform: translateY(-50%);
right: 10px;
top: 50%;
&>svg {
  font-size: 20px;
  color: ${props => props.theme.greenST};
}
`

export default function FormModifMdpST({onClose}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [passwordVisible3, setPasswordVisible3] = useState(false);

  const [form, setForm] = useState({
    Ancien: "",
    Pass1: "",
    Pass2: ""
  });

  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };

  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };

  const togglePasswordVisibility3 = () => {
    setPasswordVisible3(!passwordVisible3);
  };

 const handleOnClose = () => {
  setShowForm(false);
  }

  const sendNewMdp = () => {
    setIsLoading(true);
    axios.post("/users/membrechangemotdepassevalidation", form)
    .then(res => {
        setIsLoading(false);
        setIsErrorVisible(true);
        setTxt("Votre mot de passe a bien été modifié");
    })
    .catch(err => {
        console.log("err", err);
        setIsLoading(false);
        setTxt(err?.response?.data ? err.response.data : "Une erreur est survenue");
        setIsErrorVisible(true);
    });
}

    return (
        <Wrap>
          <h4 className="center homemadeTitle initial green m0">Modifier mon mot de passe</h4>
           {isLoading && <SpinnerST />}
           {isErrorVisible && <PopErrorST message={txt} titre={"Connexion"} onClick={() => setIsErrorVisible(false)}/>}
           {showForm === true ?
            ReactDOM.createPortal(
              <PopUpContainer className="form">
                <FormOubliPassST onClose={handleOnClose}/>
              </PopUpContainer>,
                      document.getElementById('root')
            ) : ""}
            
            <Txt> 
              </Txt>
             <FormStyled floating>
                <InputStyled
                    id="Ancien"
                    name="Ancien"
                    placeholder="Ancien mot de passe"
                    type={passwordVisible1 ? 'text' : 'password'}
                    onChange={(e) => setForm({...form, Ancien: e.target.value})}
                />
                <Eye onClick={togglePasswordVisibility1} style={{ cursor: 'pointer' }}>
                  {passwordVisible1 ? <FaEyeSlash /> : <FaEye />}
                </Eye>
                  <Label for="Ancien">Ancien mot de passe</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                    id="Pass1"
                    name="Pass1"
                    placeholder="Nouveau mot de passe"
                    type={passwordVisible2 ? 'text' : 'password'}
                    onChange={(e) => setForm({...form, Pass1: e.target.value})}
                />
                <Eye onClick={togglePasswordVisibility2} style={{ cursor: 'pointer' }}>
                  {passwordVisible2 ? <FaEyeSlash /> : <FaEye />}
                </Eye>
                  <Label for="Pass1">Nouveau mot de passe</Label>
              </FormStyled>
             <FormStyled floating>
                <InputStyled
                    id="Pass2"
                    name="Pass2"
                    placeholder="Confirmer le nouveau mot de passe"
                    type={passwordVisible3 ? 'text' : 'password'}
                    onChange={(e) => setForm({...form, Pass2: e.target.value})}
                />
                <Eye onClick={togglePasswordVisibility3} style={{ cursor: 'pointer' }}>
                  {passwordVisible3 ? <FaEyeSlash /> : <FaEye />}
                </Eye>
                  <Label for="Pass1">Confirmer le nouveau mot de passe</Label>
              </FormStyled>
              <Buts>
                <BoutonNoirBtnST lib={"Modifier mon mot de passe"} onClick={sendNewMdp} />
                <BoutonBlancBtnST lib={"Annuler"}  onClick={onClose}/>
              </Buts>
          </Wrap>
  );
}
