import React, {useState, useEffect} from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "@reach/router";
import styled from "styled-components";
import {Wrap} from "./ElementsMembre";
import BoutonNoirBtnST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import PopErrorST from "../PopupsST/PopError";
import { List } from 'antd';
import { FaFilePdf } from "react-icons/fa";

const News = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  & p {
    font-size: 18px;
  }
`;
const ListItemStyled = styled(List.Item)`
width: 100%;
list-style-type: none;
border: 1px solid ${(props) => props.theme.grey01ST} !important;
border-radius: 8px;
padding: 20px;
margin: 0 0 20px 0;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
&>.ant-list-item-extra {
  display: flex;
  align-items: end;
  margin-inline-start: 0 !important;
}
@media only screen and (max-width: 768px) {
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap !important;
  &>.ant-list-item-extra {
    margin: 0 !important;
    &> div > a {
      padding: 5px 0;
    }
  }
  }
`;

const Btn = styled.button`
background: none;
border: none;
cursor: pointer;
padding: 0;
&> svg {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
`;


export default function MembreDemandesST({className}) {
  const user = useSelector(state => state?.user?.user?.Contact);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const dispatch = useDispatch();
  const [dataDemandes, setDataDemandes] = useState([]);

  function formatDate(dateString) {
    const [year, month, day] = dateString?.split('-');
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/demandesclientliste`)
    .then((response) => {
      setDataDemandes(response.data?.DemandeClient);
        setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
      dispatch({type: "DELETE_USER"})
      navigate("/membre");
    });
  }, []);

  const viewDocument = async (event, token) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const response = await axios.post('/users/download/', { "TokenDL": token });
      const base64String = response.data.content;
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
  
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Failed to view the document:', error);
    }
  };

    return (
        <Wrap className={className}>
        {isLoading && <SpinnerInlineST/>}
        {dataDemandes ? <>
            <h3 className="center homemadeTitle initial orange">Mes demandes</h3>
            
            <List style={{width:"100%"}}
                itemLayout="vertical"
                size="large"
                pagination={{
                    position: 'bottom',
                    align: 'start',
                    total: dataDemandes?.length || 0,
                    showTotal: (total, range) => `Commande ${range[0]}-${range[1]} sur ${total} au total`, // Function to display the range of items and total
                    defaultPageSize: 5, // Number of items per page by default
                    defaultCurrent: 1, // Initial page
                    pageSizeOptions: ['10', '20', '30'], // Options for changing the page size
                    // onChange: (page, pageSize) => {
                    //   // Function to handle page change
                    // },
                  }}
                  dataSource={dataDemandes && dataDemandes?.sort((a, b) => new Date(b?.Date) - new Date(a?.Date))}
                  renderItem={(item) => {
                    return (
                      <ListItemStyled>
                      <div>
                        <p>Date de la demande: <span className="orange">{item?.Date}</span></p>
                        <p>Sujet : <b className="orange">{item?.Sujet}</b></p>
                        {item?.IDOperationsU !== "00000000-0000-0000-0000-000000000000" ? <p>Commande n° : <b className="orange">{item?.IDOperationsU}</b></p> : null}
                        <p>Demande : <span className="orange">{item?.Demande}</span></p>
                        <p>Type de demande : <span className="orange">{item?.TypeDemande}</span></p>
                        <p>Etat de la demande : <span className="orange">{item?.EtatDemande}</span></p>
                        {
                        item && item?.GED?.map((doc, index) => ( 
                            <Btn key={index} onClick={(event) => viewDocument(event, doc?.TokenDL)}><FaFilePdf key={"index"} size={40} color={"#ffce00"} title="Voir le document"/></Btn>
                        ))
                        } 
                      </div>
                    
                  </ListItemStyled>
                    );
                  }}
                /> </> : <h3 className="center homemadeTitle initial orange">Vous n'avez pas de demandes</h3>} 
                
               
        </Wrap>
  );
}
