import React, {useState, useEffect} from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import  {FormGroup ,Label, Button,ButtonGroup,Input} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import { TbNeedleThread } from "react-icons/tb";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import FormConnexionST from "./FormConnexion";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;


const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`
const ButtonGroupStyled = styled(ButtonGroup)`
gap: 20px;
`
const Buts = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
max-width: 600px;
gap: 20px;
padding-bottom: 15px;
&>p {
  margin: 0;
  font-size: 15px;
}
&>p>svg {
  height: 28px;
  width: 28px;
  margin-right: 5px;
  color: ${props => props.theme.greenST};
}
`

const ButtonStyled = styled(Button)`
background-color: ${props => props.theme.grey01ST};
color: #fff;
border: none;
border-radius: 5px !important;
max-width: 50px;
padding: 10px;
&.active {
  background-color: ${props => props.theme.redST};

}
`
const fauxData = [
  { ID: 11, Question: "Question 1" },
  { ID: 12, Titre: "Question 2" },
  { ID: 13, Titre: "Question 3" },
  { ID: 14, Titre: "Question 4" },
  { ID: 15, Titre: "Question 5" },
];

export default function FormLevelST({titre}) {
  const [selected, setSelected] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState('');
  const [data, setData] = useState([]);
  const user = useSelector((state) => state?.user?.user?.Contact);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/membreniveaucouture`)
      .then((response) => {
        setData(response?.data?.NiveauCouture);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setData([]);
        setTxt(error?.response?.data?.error?.Erreur ? error?.response?.data?.error?.Erreur : "Une erreur s'est produite. Veuillez réessayer ultérieurement.");
        setIsErrorVisible(true);
        dispatch({type: "DELETE_USER"})
      });
  }, [user]); 

  const sendForm = () => {
    setIsLoading(true);
    const reponses = Object.keys(selected).map((key) => ({
      ID: key,
      Reponse: selected[key],
    }));

    axios.post(`/users/membreniveaucouturevalidation`, {Reponses: reponses})
      .then((response) => {
        setIsLoading(false);
        setTxt("Votre formulaire a été envoyé");
        setIsErrorVisible(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setTxt(error?.response?.data?.error?.Erreur ? error?.response?.data?.error?.Erreur : "Une erreur s'est produite. Veuillez réessayer ultérieurement.");
        setIsErrorVisible(true);
        dispatch({type: "DELETE_USER"})
      });
  }
  
    return (
        <Wrap>
          {isLoading && <SpinnerST/>}
          {isErrorVisible && <PopErrorST message={txt} titre={"Niveau de couture"} onClick={() => { setIsErrorVisible(false);}} />}
            {!user ? <FormConnexionST/> :(
              <>
            <Txt> 
              <h4 className="initial">{titre}</h4>
              </Txt>
                  {data && data?.sort((a, b) => a?.Tri - b?.Tri).map((item, index) => (
                      <Buts key={index}>
                          <p><TbNeedleThread />{item?.Question+ " ("+item?.Aide+")"}</p>
                          <ButtonGroupStyled>
                            <ButtonStyled
                              outline
                              onClick={() => { setSelected({ ...selected, [item?.ID]: 2 }) }}
                              active={selected[item?.ID] === 2}
                            >
                              OUI
                            </ButtonStyled>
                            <ButtonStyled
                              outline
                              onClick={() => { setSelected({ ...selected, [item?.ID]: 3 }) }}
                              active={selected[item?.ID] === 3}
                            >
                              NON
                            </ButtonStyled>
                            <ButtonStyled
                              outline
                              onClick={() => { setSelected({ ...selected, [item?.ID]: 1 }) }}
                              active={selected[item?.ID] === 1}
                            >
                              NSP
                            </ButtonStyled>
                          </ButtonGroupStyled>
                      </Buts>
                  ))}
               <BoutonNoirST width={"600px"} lib="Soumettre" onClick={() => sendForm()} />
               </>
            )}
          </Wrap>
  );
}

