// Import any necessary action types
// import { SET_USER, UPDATE_USER, DELETE_USER } from '../actions/types';

// Define the initial state
const initialState = {
    adresse: null,
};

const adresseReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ADRESSE":
            return {
                ...state,
                adresse: {
                    IDAdresse: action.payload.IDAdresse,
                }
            };

        case "DELETE_ADRESSE":
            return {
                ...state,
                adresse: null,
            };
        default:
            return state;
    }
};

export default adresseReducer;