// Import any necessary action types
// import { SET_USER, UPDATE_USER, DELETE_USER } from '../actions/types';

// Define the initial state
const initialState = {
    date: null,
};

const dateReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_DATE":
            return {
                ...state,
                date: action.payload,
            };

        case "DELETE_DATE":
            return {
                ...state,
                date: null,
            };
        default:
            return state;
    }
};

export default dateReducer;