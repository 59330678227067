import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import userReducer from "./reducers/userReducer";
import basketReducer from "./reducers/basketReducer";
import favorisReducer from "./reducers/favorisReducer";
import magasinReducer from "./reducers/magasinsReducer";
import relaisReducer from "./reducers/relaisReducer";
import adresseReducer from "./reducers/adresseReducer";
import modeLivrReducer from "./reducers/modeLivrReducer";
import dateReducer from "./reducers/dateReducer";
import heureReducer from "./reducers/heureReducer";

const reducers = combineReducers({

    user: userReducer,
    basket: basketReducer,
    favoris: favorisReducer,
    magasin: magasinReducer,
    relais: relaisReducer,
    adresse: adresseReducer,
    modeLivr: modeLivrReducer,
    date: dateReducer,
    heure: heureReducer,
 
});
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'basket', 'favoris', 'magasin', 'relais', 'adresse', 'modeLivr', 'date', 'heure'],
    blacklist: [],
};
 
const persistedReducer = persistReducer(persistConfig, reducers);
 
 
 
export default configureStore({
    reducer: persistedReducer,
    // middleware: [thunk],
    // devTools: process.env.NODE_ENV !== 'production',
 
});