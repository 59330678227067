import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, {keyframes} from "styled-components";
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";
import { 
  TabContent, TabPane, 
  Row, Col , Nav, NavItem, NavLink,
} from 'reactstrap'; 
import SpinnerST from '../DesignSystem/SpinnersST/Spinner';

const Wrap = styled.div`
display: flex;
width: 100%;
gap: 50px;
@media screen and (max-width: 768px) {
  flex-direction: column;
  gap: 20px;
}
`

const NavStyled = styled(Nav)`
width: 20%;
display: flex;
flex-direction: column;
@media screen and (max-width: 768px) {
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
`
const TabContentStyled = styled(TabContent)`
width: 80%;
@media screen and (max-width: 768px) {
  width: 100%;
}
`

const WrapAccordeon = styled.div`
width: 100%;
margin: 0 auto;
padding: 0 20px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap:15px;
&::after {
    content: "";
    width: 100%;
    background-color: ${props => props.theme.grey02ST};
    height: 1px;
}
@media screen and (max-width: 600px) {
    padding: 30px 20px;
}
`
const Btn = styled.button`
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px 0 10px 0;
border: none;
background-color: transparent;
margin: 0 auto;
width: 100%;
color: ${props => props.theme.blackST};
font-size: 20px;
text-align: left;
cursor: pointer;

&>svg{color:${props => props.theme.grey02ST};
min-width: 25px;
height: 30px;
margin-right: 10px;
}

&:hover {
  color: ${props => props.theme.greenST};
    &>svg{
        color: ${props => props.theme.greenST};
    }
}


@media screen and (max-width: 768px) {
font-size: 17px;
}

&.open{
&>svg.plus{display:none;}
&>svg.minus{display:inline-block}
}
&.closed{
&>svg.plus{display:inline-block;}
&>svg.minus{display:none}
}
`
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const FadeOpen = styled.div`
width: 100%;
animation: ${fadeIn} 0.6s;
border-radius: 10px;
font-size: 20px;
text-align: left;
margin: auto;
background-color: #f05b3217;
@media screen and (max-width: 430px) {
overflow: auto;
}
`;

const NavLinkStyled = styled(NavLink)`
font-size: 20px;
border: none !important;
color: ${props => props.theme.orangeST} !important;
background-color: transparent !important;
&.active {
color: ${props => props.theme.greenST} !important;
border-bottom: 2px solid ${props => props.theme.greenST} !important;
}
&:hover>div {
text-decoration: none;
color: ${props => props.theme.greenST};
}
`;

const NavItemStyled = styled(NavItem)`
@media only screen and (max-width: 768px) {

}

`;

const TabTitle = styled.div`
  font-size: 18px;
  `


export default function InfosListeST({}) {
  const [frais, setFrais] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/fraisdeportliste`,{Limite : "",OFFSET : "" })
    .then((response) => {
      setFrais(response?.data?.Frais);
        setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
    });
  }, []);
  console.log("frais", frais);
  const TabLivr1 = ({dataFrais}) => {
    return (
      <>
        <table>
        <thead>
          <tr>
            <th>Zone géographique</th>
            <th>Montant de la commande en € TTC</th>
            <th>Poids de la commande en gramme</th>
            <th>&nbsp;Tarif&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {dataFrais?.map((item, index) => (
            <tr key={index}>
              <td>{item?.ZoneGeographiqueLibelle}</td>
              <td>{item?.TypePoidsOuMontant === 2 ? `${item?.TrancheDebut} - ${item?.TrancheFin}` : "-"}</td>
              <td>{item?.TypePoidsOuMontant === 1 ? `${item?.TrancheDebut} - ${item?.TrancheFin}` : "-"}</td>
              <td>{item?.Montant} €</td>
            </tr>
          ))}
        </tbody>
      </table>
      </>
    );
  };  
  
  const TabLivr2 = () => {
    return (
      <>
        <p>Frais de livraison <strong>Colissimo</strong> hors France métropolitaine</p>
        <p>International zone 1 à 6 :</p>
        <table>
        <thead>
        <tr>
        <th>KG</th>
        <th>I1</th>
        <th>I2</th>
        <th>I3</th>
        <th>I4</th>
        <th>I5</th>
        <th>I6</th>
        </tr> 
        </thead>
        <tbody>
        <tr>
        <td>0-0.5 kg</td>
        <td>10.81€</td>
        <td>12.42€</td>
        <td>13.84€</td>
        <td>16.22€</td>
        <td>31.91€</td>
        <td>36.59€</td>
        </tr>
        <tr>
        <td>0.51-1kg</td>
        <td>13.20€</td>
        <td>15.80€</td>
        <td>17.20€</td>
        <td>19.84€</td>
        <td>35.28€</td>
        <td>43.05€</td>
        </tr>
        <tr>
        <td>1.01-2kg</td>
        <td>14,12€</td>
        <td>17.67€</td>
        <td>19.24€</td>
        <td>21.83€</td>
        <td>48.39€</td>
        <td>59.12€</td>
        </tr>
        <tr>
        <td><span>2.01-3kg</span></td>
        <td>15.16€</td>
        <td>18.82€</td>
        <td>20.97€</td>
        <td>23.94€</td>
        <td>61.46€</td>
        <td>75.50€</td>
        </tr>
        <tr>
        <td><span>3.01-4kg</span></td>
        <td>16.19€</td>
        <td>19.85€</td>
        <td>23.10€</td>
        <td>26.78€</td>
        <td>70.44€</td>
        <td>91.56€</td>
        </tr>
        <tr>
        <td><span>4.01-5kg</span></td>
        <td>17.12€</td>
        <td>20.93€</td>
        <td>25.23€</td>
        <td>32.38€</td>
        <td>75.20€</td>
        <td>105.27€</td>
        </tr>
        <tr>
        <td><span>5.01-6kg</span></td>
        <td>18.07€</td>
        <td>21.94€</td>
        <td>33.22€</td>
        <td>36.63€</td>
        <td>96.12€</td>
        <td>120.94€</td>
        </tr>
        <tr>
        <td><span>6.01-7kg</span></td>
        <td>19.02€</td>
        <td>23.12€</td>
        <td>35.12€</td>
        <td>41.74€</td>
        <td>111.28€</td>
        <td>136.74€</td>
        </tr>
        <tr>
        <td><span>7.01-8kg</span></td>
        <td>19.97€</td>
        <td>24.11€</td>
        <td>36.89€</td>
        <td>47.93€</td>
        <td>123.29€</td>
        <td>152.43€</td>
        </tr>
        <tr>
        <td><span>8.01-9kg</span></td>
        <td>20.93€</td>
        <td>25.13€</td>
        <td>38.65€</td>
        <td>51.52€</td>
        <td>128.49€</td>
        <td>168.46€</td>
        </tr>
        <tr>
        <td><span>9.01-10kg</span></td>
        <td>21.88€</td>
        <td>26.16€</td>
        <td>40.40€</td>
        <td>55.90€</td>
        <td>134.88€</td>
        <td>184.14€</td>
        </tr>
        <tr>
        <td><span>10.01-15kg</span></td>
        <td>27.30€</td>
        <td>31.80€</td>
        <td>50.40€</td>
        <td>75.63€</td>
        <td>197.50€</td>
        <td>255.15€</td>
        </tr>
        <tr>
        <td><span>15.01-20kg</span></td>
        <td>32.74€</td>
        <td>37.30€</td>
        <td>60.24€</td>
        <td>88.72€</td>
        <td>216.30€</td>
        <td>327.70€</td>
        </tr>
        <tr>
        <td><span>20.01-25kg</span></td>
        <td>38.18€</td>
        <td>42.78€</td>
        <td>70.14€</td>
        <td>105.08€</td>
        <td>277.65€</td>
        <td>398.69€</td>
        </tr>
        <tr>
        <td><span>25.01-30kg</span></td>
        <td>43.61€</td>
        <td>48.30€</td>
        <td>72.95€</td>
        <td>121.92€</td>
        <td>319.97€</td>
        <td>469.69€</td>
        </tr>
        </tbody>
        </table>
      </>
    );
  };  
  
  const TabLivr3 = () => {
    return (
      <>
        <p>Conformément à l'article L. 221-21 du code de la consommation, l'Acheteur dispose d'un délai de 14 jours à compter de la date de réception, pour annuler totalement ou partiellement sa commande, et retourner à ses frais les produits commandés, pour remboursement.</p>
        <p>Les produits doivent être restitués dans les conditions suivantes :</p>
        <p>l’acheteur doit :</p>
        <p>1. Utiliser le formulaire de rétractation en annexe aux présentes conditions générales de vente (Annexe 1) et l’adresser par Email à la EURL SELF TISSUS à l’adresse suivante : <a href="mailto:serviceclient@selftissus.fr">serviceclient@selftissus.fr</a>. La EURL SELF TISSUS fourni alors un numéro de retour.</p>
        <p>2. Utiliser l'emballage d'origine pour retourner le produit.</p>
        <p>3. Faire apparaître lisiblement le numéro de retour sur l'emballage. Les retours sans numéro de retour ne sont pas acceptés.</p>
        <p>4. Envoyer le colis à l'adresse suivante :<br></br>SELF TISSUS<br></br>Service Retours<br></br>10 rue Louison Bobet<br></br>29000 Quimper</p>
        <p>La EURL SELF TISSUS n'accepte pas les colis adressés en port dû.</p>
        <p>Les produits doivent impérativement être retournés à la EURL SELF TISSUS dans un parfait état de revente, dans leur état d'origine (emballage, accessoires, notice...).</p>
        <p>A réception des produits en parfait état, le remboursement sera adressé à l’Acheteur sous 14 jours.</p>
      </>
    );
  };
  const data = [
    {
      Questions: "Frais de livraison en France métropolitaine",
      Réponses: <TabLivr1/>
    },
    {
      Questions: "Frais de livraison hors France métropolitaine",
      Réponses: <TabLivr2/>
    },
  ];
  const data2 = [
    {
      Questions: "Renvoyer ma commande",
      Réponses: <TabLivr3/>
    },
  ];
const [isOpened, setIsOpened] = useState(false);
const [activeTab, setActiveTab] = useState(0);
const toggle = tab => {
  if(activeTab !== tab) setActiveTab(tab);
}


const Content = () => {
  return (
    frais?.map((item, index) => (
      item?.LibelleCategorie !== "" ? 
      <WrapAccordeon key={index}>
        <Btn
          onClick={() => {
            setIsOpened(prevIsOpened => index === prevIsOpened ? null : index);
          }}
          aria-expanded={isOpened === index ? "true" : "false"}
          className={isOpened === index ? 'open' : 'closed'}
        >
          <div>{item?.LibelleCategorie}</div>
          <FaChevronUp className="minus"/>
          <FaChevronDown className="plus"/>
        </Btn>
        {isOpened === index ? (
        <FadeOpen>
            <TabLivr1 dataFrais={item?.Tarif}/>
        </FadeOpen>
      ) : null}
      </WrapAccordeon> : null
    ))
  )
}
const Content2 = ({content}) => {
  return (
    data2.map((item, index) => (
      item.Questions !== "" ? 
      <WrapAccordeon key={index}>
        <Btn
          onClick={() => {
            setIsOpened(prevIsOpened => index === prevIsOpened ? null : index);
          }}
          aria-expanded={isOpened === index ? "true" : "false"}
          className={isOpened === index ? 'open' : 'closed'}
        >
          <div>{item.Questions}</div>
          <FaChevronUp className="minus"/>
          <FaChevronDown className="plus"/>
        </Btn>
        {isOpened === index ? (
        <FadeOpen style={{ padding: item.Réponses ? '25px' : '0' }}>
          <div>
            {item.Réponses}
          </div>
        </FadeOpen>
      ) : null}
      </WrapAccordeon> : null
    ))
  )
}

const tabData = [
  { label: <TabTitle>Livraison</TabTitle>, content: <Content/>,},
  { label: <TabTitle>Retours</TabTitle>, content: <Content2/>},
];
return (
            <>
             {isLoading && <SpinnerST/>}
            <Wrap>
            <NavStyled tabs>
              {tabData.map((tab, index) => (
                <NavItemStyled className={index}>
                  <NavLinkStyled
                    className={activeTab === index ? 'active' : ''}
                    onClick={() => toggle(index)}
                  >
                    {tab.label}
                  </NavLinkStyled>
                </NavItemStyled>
              ))}
            </NavStyled>
            <TabContentStyled activeTab={activeTab}>
              {tabData.map((tab, index) => (
                <TabPane key={index} tabId={index}>
                  <Row>
                    <Col sm="12">
                      {tab.content}
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContentStyled>
            </Wrap>
              </>
            );
}

