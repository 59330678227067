// Import any necessary action types
// import { SET_USER, UPDATE_USER, DELETE_USER } from '../actions/types';

// Define the initial state
const initialState = {
    modeLivraison: 1,
};

const adresseReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_MODELIVR":
            return {
                ...state,
                modeLivraison: action.payload.modeLivraison
            };

        case "DELETE_MODELIVR":
            return {
                ...state,
                modeLivraison: 1,
            };
        default:
            return state;
    }
};

export default adresseReducer;