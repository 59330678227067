import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from "styled-components";
import { Button, Steps } from 'antd';
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { AiOutlineEnvironment,AiOutlineUser,AiOutlineEuro,AiOutlineSolution } from "react-icons/ai";
import { MdOutlineLocalShipping } from "react-icons/md";
import PanierRecapST from "./PanierRecap";
import Paiement from "./Paiement";
import FormConnexionST from '../FormsST/FormConnexion';
import Adresse from "./Adresse";
import { FaArrowLeft,FaArrowRight  } from "react-icons/fa";
import LivraisonST from './Livraison';
import { useSelector, useDispatch } from "react-redux";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }
`;

const StepsStyled = styled(Steps)`
&.user {
  width: 100%;
}
width: 10%;
@media only screen and (max-width: 768px) {
  width: 100%;
}
font-family: 'Assistant', sans-serif;
flex-wrap: wrap;
@media only screen and (max-width: 768px) {
  align-items: flex-start;
}
&.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item-wait > div > div.ant-steps-item-icon >span > svg {
  fill: ${props => props.theme.grey01ST} !important;
}
&.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: ${props => props.theme.orangeST} !important;
}
&.ant-steps.ant-steps-vertical >.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
  padding: 35px 0 0px;
}
&.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: ${props => props.theme.orangeST} !important;
}
&>.ant-steps-item {
}
&>.ant-steps-item> div {
    display: flex;
    align-items: center;
    min-width: 130px;
    @media only screen and (max-width: 768px) {
        min-width: unset;
        align-items: stretch;
    }
    &:hover { 
        &>div >.ant-steps-item-title {
            color: ${props => props.theme.blackST} !important;
        }
        
    }
    &>.ant-steps-item-icon > span {
        color: ${props => props.theme.greenST} !important;
    }
}
`;

const WrapStep = styled.div`
width: 100%;
margin-top: 20px;
`;

const Buts = styled.div`
display: flex;
gap: 20px;
justify-content: center;
align-items: center;
width: 100%;
margin-top: 30px;
`;

const ButtonStyled = styled(Button)`
background-color: ${props => props.theme.greenST};
border: none;
color: #fff;
width: 160px;
height: 60px;
&>svg {
  font-size: 30px;
}
&:hover {
    opacity: 0.8;
    color: ${props => props.theme.greenST} !important;
    background-color: ${props => props.theme.orangeST} !important;
}
`;




export default function CheckoutST({className}) {
    const [current, setCurrent] = useState(0);
    const user = useSelector(state => state.user?.user?.Contact);
    const basket = useSelector(state => state.basket);
    const mag = useSelector(state => state.magasin.magasin);
    const relais = useSelector(state => state.relais);
    const adresse = useSelector(state => state.adresse.adresse);
    const modeL = useSelector(state => state.modeLivr?.modeLivraison);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [appliedPromoCode, setAppliedPromoCode] = useState('');
    const [appliedCarteCadeau, setAppliedCarteCadeau] = useState('');
    const [blockSteps, setBlockSteps] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [txt, setTxt] = useState("");
    const [dataPaiement, setDataPaiement] = useState({});
    const dispatch = useDispatch();
    const [blockIfWrorgAdresse, setBlockIfWrorgAdresse] = useState(false);
    const date = useSelector(state => state?.date?.date);
    const heure = useSelector(state => state?.heure?.heure);
    const [blockIfNotDateHeure, setBlockIfNotDateHeure] = useState(false);
  
console.log("setBlockIfWrorgAdresse", blockIfWrorgAdresse);
    useEffect(() => {
      if (date && heure) {
        setBlockIfNotDateHeure(false);
      } else if ((!date || !heure) && modeL===2 && mag?.ID !== "50ec9764-679e-4acf-8945-61a97ffcb8d5") {
        setBlockIfNotDateHeure(true);
      }
    }, [date, heure]);
console.log("blockIfNotDateHeure",blockIfNotDateHeure);

    const handleApplyPromo = (promoCode) => {
      setAppliedPromoCode(promoCode);
    };
    const handleApplyCarteCadeau = (codeCarteCadeau) => {
      setAppliedCarteCadeau(codeCarteCadeau);
    };

    const isAtelierOnly = basket.items.every(item => item.mode === "atelier");
    useEffect(() => {
      if (isAtelierOnly) {
        dispatch({
          type: "DELETE_RELAIS",
        });
        dispatch({
          type: "DELETE_ADRESSE",
        });
        dispatch({
          type: "DELETE_MODELIVR",
        });
        dispatch({
          type: "SET_MAGASIN",
          payload: {
              ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
              Nom: "WEB"
          }
      });
      }
    }, [isAtelierOnly]);

    const handleCheckboxChange = (e) => {
      setIsTermsAccepted(e.target.checked);
    };

    const handleNotInStockChange = (notInStock) => {
      setBlockSteps(notInStock);
    };

    const sauvePanier = () => {
      return new Promise((resolve, reject) => {
        if (!isTermsAccepted) {
          return 
        }
        const atelierItems = basket?.items?.filter(item => item.mode === "atelier").map((item, index) => ({
          ID: item?.ID,
          quantite: item?.quantity,
          IDAtelierPlanification: item?.planifId,
        }));
        
        const articleItems = basket?.items?.filter(item => item.mode === "article").map((item, index) => ({
          ID: item?.ID,
          quantite: item?.quantity,
        }));
        
        const relevantBasketData = {
          ateliers: atelierItems,
          articles: articleItems,
        };
    
        console.log("relevantBasketData", relevantBasketData);
      
        const dataToSend = {
          Articles: articleItems,
          Atelier: atelierItems,
          ModeLivraison: modeL,
          Date: date?.date,
          Heure: heure?.heure,
          UIDMagasin: mag?.ID,
          IDAdresseLiv: adresse?.IDAdresse,
          CodePromo: appliedPromoCode,
          CarteCadeau: appliedCarteCadeau,
        };
        console.log("dataToSend", dataToSend);
        setIsLoading(true);
        axios.post(
          `/users/sauvepanier`,
          { Panier: dataToSend },
          { headers: { 'Content-Type': 'application/json' } }
        )
        .then(response => {
          console.log("response", response);
          setDataPaiement(response?.data?.Panier);
          setIsLoading(false);
          resolve();
        })
        .catch(error => {
          console.log("error", error?.response);
          setIsLoading(false);
          setIsErrorVisible(true);
          setTxt(error?.response?.data?.error?.Erreur ? error?.response?.data?.error?.Erreur : "Une erreur est survenue lors de la validation du panier");
          reject(error);
          dispatch({type: "DELETE_USER"})
        });
      });
    };

    const alwaysVisibleSteps = [
      // {
      //   title: 'Récapitulatif',
      //   content: <PanierRecapST/>,
      //   icon: <AiOutlineSolution />,
      // },
    ];
    
    const conditionalSteps = user ? [
      // ...(!isAtelierOnly ? [
      {
        title: 'Livraison',
        content: <LivraisonST onNotInStockChange={handleNotInStockChange}  atelierOnly={isAtelierOnly}/>,
        icon: <MdOutlineLocalShipping />,
      },
    // ] : []),
      {
        title: 'Adresse',
        content: <Adresse 
          atelierOnly={isAtelierOnly}
          setBlockIfWrorgAdresse={setBlockIfWrorgAdresse}
        />,
        icon: <AiOutlineEnvironment />,
      },
      {
        title: 'Récapitulatif',
        content: <PanierRecapST atelierOnly={isAtelierOnly} isTermsAccepted={isTermsAccepted} handleCheckboxChange={handleCheckboxChange} onApplyPromo={handleApplyPromo} onApplyCarteCadeau={handleApplyCarteCadeau} />,
        icon: <AiOutlineSolution />,
        disabled: blockSteps || blockIfWrorgAdresse || blockIfNotDateHeure,
      },
      {
        title: 'Paiement',
        content: <Paiement data={dataPaiement} atelierOnly={isAtelierOnly}/>,
        icon: <AiOutlineEuro />,
        disabled: !isTermsAccepted || blockSteps || blockIfWrorgAdresse || blockIfNotDateHeure,
        onClick: sauvePanier,
      },
    ] : [
      {
        title: 'Connexion',
        content: <FormConnexionST />,
        icon: <AiOutlineUser />,
      },
    ];
    const next = () => {
      window.scrollTo(0, 0);
      setCurrent(current + 1);
    };
  
    const prev = () => {
      window.scrollTo(0, 0);
      setCurrent(current - 1);
    };

    const onChange = (value) => {
        console.log('onChange:', value);
        setCurrent(value);
      };

   
  
      const steps = [ ...conditionalSteps];

      const items = steps.map((item) => ({ key: item.title, title: item.title, icon: item.icon,disabled: item.disabled, onClick: item.onClick }));

    return (
        <Wrap className={className}>
          {isLoading && <SpinnerST />}
          {isErrorVisible && <PopErrorST message={txt} titre={"Validation du panier"} onClick={() => { setIsErrorVisible(false);}} />}
            {/* <LienNoirST className="right" width={"100%"} lib={"Continuer les achats"} /> */}
            <StepsStyled current={current} items={items} onChange={onChange} className={user ? "user" : ""}/>
                <WrapStep>{steps[current]?.content}</WrapStep>
                <Buts>
                {current > 0 && (
                    <ButtonStyled style={{ margin: '0 8px' }} onClick={() => prev()}>
                        <FaArrowLeft/>
                    </ButtonStyled>
                    )}
                    {current < steps.length - 1 && (
                      console.log("current",current),
                    <ButtonStyled 
                    onClick={async () => {
                      if (current === 2) {
                        try {
                          await sauvePanier();
                          next();
                        } catch (error) {
                          console.error("Error during sauvePanier:", error);
                        }
                      } else {
                        next();
                      }
                    }}
                    disabled={
                      (current === steps.length - 2 && !isTermsAccepted) || 
                      ((current === steps.length - 4 || current === steps.length - 3) && blockSteps) ||
                      ((current === steps.length - 3 || current === steps.length - 2) && blockIfWrorgAdresse ) ||
                      ((current === steps.length - 3 || current === steps.length - 2) && blockIfNotDateHeure)
                    }
                    >
                    <FaArrowRight/>
                    </ButtonStyled>
                    )}
                    {/* {current === steps.length - 1 && (
                    <ButtonStyled type="primary" onClick={() => message.success('Processing complete!')}>
                        <FaCheck/>
                    </ButtonStyled>
                    )} */}
                </Buts>
        </Wrap>
  );
}
