import React, {useState, useEffect} from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import {Wrap} from "./ElementsMembre";
import {StyledTable} from "./ElementsMembre";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";

export default function MembrePointsST({className}) {
  const [dataPoints, setDataPoints] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/membrepointfidelite`)
    .then((response) => {
      console.log("response", response);
      setDataPoints(response.data);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
      setIsLoading(false);
      dispatch({type: "DELETE_USER"})
      navigate("/membre");
    });
  }, []);

    return (
        <Wrap className={className}>
          <h3 className="center homemadeTitle initial orange">Mes points de fidélité</h3>
          {isLoading && <SpinnerInlineST/>}
          <h4 className="center grey initial">Total de points : <span className="orange">{dataPoints?.NbPointsTotal}</span></h4>
          {dataPoints?.Point &&
          <div style={{width: '100%'}}>
          {/* <StyledTable
            columns={[
              {
                title: 'Date',
                dataIndex: 'Date',
                key: 'Date',
                width: 150,
              },
              {
                title: 'Libellé',
                dataIndex: 'Libelle',
                key: 'Libelle',
                width: 300,
              },
              {
                title: 'Nombre de points',
                dataIndex: 'NbPoint',
                key: 'NbPoint',
                width: 150,
              },
            ]}
            dataSource={dataPoints.Point}
            pagination={false}
            scroll={{ x: 600 }} // Add horizontal scroll
          /> */}
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Nombre de points</th>
                  </tr>
                </thead>
                <tbody>
                  {dataPoints?.Point?.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.Date}</td>
                      <td>{item?.Libelle}</td>
                      <td>{item?.NbPoint}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </div>
         }
          
        </Wrap>
  );
}
