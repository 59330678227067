import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, {keyframes} from "styled-components";
import ReactHtmlParser from 'react-html-parser';
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";
import { 
  TabContent, TabPane, 
  Row, Col , Nav, NavItem, NavLink,
} from 'reactstrap'; 
import FormSearchFaqSelfST from '../FormsST/FormSearchFaqSelf';
import SpinnerST from '../DesignSystem/SpinnersST/Spinner';
import PopErrorST from "../PopupsST/PopError";
import { isError } from 'util';

const Wrap = styled.div`
display: flex;
width: 100%;
gap: 50px;
@media screen and (max-width: 768px) {
  flex-direction: column;
  gap: 20px;
}
`

const NavStyled = styled(Nav)`
width: 20%;
display: flex;
flex-direction: column;
@media screen and (max-width: 768px) {
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
`
const TabContentStyled = styled(TabContent)`
width: 80%;
@media screen and (max-width: 768px) {
  width: 100%;
}
`

const WrapAccordeon = styled.div`
width: 100%;
margin: 0 auto;
padding: 0 20px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap:15px;
&::after {
    content: "";
    width: 100%;
    background-color: ${props => props.theme.grey02ST};
    height: 1px;
}
@media screen and (max-width: 600px) {
    padding: 30px 20px;
}
`
const Btn = styled.button`
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px 0 10px 0;
border: none;
background-color: transparent;
margin: 0 auto;
width: 100%;
color: ${props => props.theme.blackST};
font-size: 20px;
text-align: left;
cursor: pointer;

&>svg{color:${props => props.theme.grey02ST};
min-width: 25px;
height: 30px;
margin-right: 10px;
}

&:hover {
  color: ${props => props.theme.greenST};
    &>svg{
        color: ${props => props.theme.greenST};
    }
}


@media screen and (max-width: 768px) {
font-size: 17px;
}

&.open{
&>svg.plus{display:none;}
&>svg.minus{display:inline-block}
}
&.closed{
&>svg.plus{display:inline-block;}
&>svg.minus{display:none}
}
`
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const FadeOpen = styled.div`
width: 100%;
animation: ${fadeIn} 0.6s;
border-radius: 10px;
font-size: 20px;
text-align: left;
margin: auto;
background-color: #f05b3217;
`;

const NavLinkStyled = styled(NavLink)`
font-size: 20px;
border: none !important;
color: ${props => props.theme.orangeST} !important;
background-color: transparent !important;
&.active {
color: ${props => props.theme.greenST} !important;
border-bottom: 2px solid ${props => props.theme.greenST} !important;
}
&:hover>div {
text-decoration: none;
color: ${props => props.theme.greenST};
}
`;

const NavItemStyled = styled(NavItem)`
@media only screen and (max-width: 768px) {

}

`;


export default function FaqST({}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  useEffect(() => {
      setIsLoading(true);
      axios.post(`/users/faqliste`,{Limite : "",OFFSET : "" })
      .then((response) => {
        setQuestions(response?.data?.FAQ);
          setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        setIsErrorVisible(true);
        setTxt("Une erreur est survenue lors du chargement des données.<br> Veuillez réessayer plus tard.");
      });
    }, []);

const [isOpened, setIsOpened] = useState(false);
const [activeTab, setActiveTab] = useState(0);
const toggle = tab => {
  if(activeTab !== tab) setActiveTab(tab);
}

const handleSearchChange = (event) => {
  setSearchTerm(event.target.value);
};
const filteredData = searchTerm === '' ? questions : questions?.map(category => {
  const filteredQuestions = category?.Question?.filter(item =>
    item?.Question?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item?.Reponse?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return { ...category, Question: filteredQuestions };
}).filter(category => category?.Question?.length > 0);
console.log("filteredData",filteredData)

const highlightText = (text, highlight) => {
  if (!highlight) return text;
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      `<span key=${index} style="background-color: #ffce00;border-radius:5px">${part}</span>`
    ) : (
      part
    )
  ).join('');
};

const Content = ({content}) => {
  console.log("content",content);
  return (
    content?.sort((a, b) => a.Priorite - b.Priorite).map((item, index) => (
      item.Question !== "" ? 
      <WrapAccordeon key={index}>
        <Btn
          onClick={() => {
            setIsOpened(prevIsOpened => index === prevIsOpened ? null : index);
          }}
          aria-expanded={isOpened === index ? "true" : "false"}
          className={isOpened === index ? 'open' : 'closed'}
        >
          <div>{ReactHtmlParser(highlightText(item.Question, searchTerm))}</div>
          <FaChevronUp className="minus"/>
          <FaChevronDown className="plus"/>
        </Btn>
        {isOpened === index ? (
        <FadeOpen style={{ padding: item.Reponse ? '25px' : '0' }}>
          <div>
            {ReactHtmlParser(highlightText(item.Reponse, searchTerm))}
          </div>
        </FadeOpen>
      ) : null}
      </WrapAccordeon> : null
    ))
  )
}

return (
            <>
            {isLoading && <SpinnerST/>}
            {isErrorVisible && <PopErrorST classNameBut={"white"} message={txt} titre={"FAQ"} onClick={() => { setIsErrorVisible(false)}} />}
            <FormSearchFaqSelfST className={"max"} 
            placeholder={"Tapez le mot clé pour trouver la réponse"}  
            value={searchTerm}
            onChange={handleSearchChange}/>
            <Wrap>
            <NavStyled tabs>
              {filteredData?.sort((a, b) => a.Priorite - b.Priorite)?.map((tab, index) => (
                <NavItemStyled className={index}>
                  <NavLinkStyled
                    className={activeTab === index ? 'active' : ''}
                    onClick={() => toggle(index)}
                  >
                    {tab?.CategorieFAQ}
                  </NavLinkStyled>
                </NavItemStyled>
              ))}
            </NavStyled>
            <TabContentStyled activeTab={activeTab}>
              {filteredData?.map((tab, index) => (
                <TabPane key={index} tabId={index}>
                  <Row>
                    <Col sm="12">
                    <Content content={tab?.Question} />
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContentStyled>
            </Wrap>
              </>
            );
}

