import React, {useState,useEffect} from 'react';
import { navigate } from '@reach/router';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import styled from "styled-components";
import { Card } from 'antd';
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { InputStyled, FormGroupStyled } from "../DesignSystem/SelfTissus/Forms";
import { FaTrashAlt } from "react-icons/fa";
import BoutonNoirLnkST from "../DesignSystem/SelfTissus/BoutonNoirLnk";
import BoutonNoirBtnST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import {Badge} from 'antd';
import PopErrorST from "../PopupsST/PopError";
import BoutonBlancBtnST from '../DesignSystem/SelfTissus/BoutonBlancBtn';
import picDefault from "../../assets/images/photo.jpg";

const BadgeStyled = styled(Badge)`
  .ant-badge-status-dot {
    width: 13px !important;
    height: 13px !important;
    margin-right: 5px;
  }
  `;

const Stock = styled.div`
position: absolute;
right: 0px;
top: 0px;
`;

const CardGridStyled = styled(Card.Grid)`
position: relative; 
&.nom {
    width: 60%;
    &.mob {
       width: 100%; 
       padding: 15px;
    }
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}

&.quantite{
    width: 25%;
    &.mob {
       width: 100%; 
       padding: 20px;
    }
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
&.prix{
    width: 15%;
    &.mob {
       width: 100%; 
       padding: 15px;
    }
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
`;

const PanierWrap = styled.div`
width: 100%;
&>.ant-card {
   background: #ffffff75;
   padding: 20px;
}
&>.ant-card > div.ant-card-body > .ant-card-grid {
    display: flex;
    align-items: center;
}
&.mob {
    &>.ant-card > div.ant-card-body > .ant-card-grid.pc {
    display: none;
}
}
&>.ant-card > div.ant-card-body > .ant-card-grid.pc {
    @media only screen and (max-width: 786px) {
        display: none;
    }
}
`;

const Produit = styled.div`
position: relative;
display: flex;
flex-wrap: wrap;
align-items: center;
gap: 30px;
`

const Img = styled.img`
width: 100px;
height: 100px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
`
const Txt = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 20px;
flex-wrap: wrap;
&>span {
    font-size: 20px; 
    color: ${props => props.theme.grey02ST};
}
&.mob {
    &>span {
        padding: 0;
    }
    &>a {
        margin: 0;
    }
}
`;

const Message = styled.div`
background: ${props => props.theme.orangeST};
padding: 20px;
margin: 35px 0;
border-radius: 5px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
  &>ul {
    margin: 0;
  }

`;

const Prix = styled.div`
color: ${props => props.theme.grey02ST};
text-align: right;
width: 100%;
&.mob {
    text-align: center;
}
`

const Quantity = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 20px;
justify-content: space-between;
&>.trash {
    font-size: 20px;
    color: ${props => props.theme.grey02ST};
    &:hover {
        color: ${props => props.theme.redST};
        cursor: pointer;
    }
}
` 

const Total = styled.div`
padding: 20px 0;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
gap: 20px;
&>div,a {
    width: 40%;
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
&.mob {
    &>div,a {
        width: 100%;
    }
}
`
const Message2 = styled.span`
position: absolute;
bottom: 10px;
color: ${(props) => props.theme.orangeST};
font-size: 14px; 
&.mob {
    bottom: -3px;
}
@media only screen and (max-width: 786px) {
    font-size: 12px;
    bottom: -1px;
}
`;

const NoProducts = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
`

const Planif = styled.div`
font-size: 14px;
color: ${props => props.theme.grey02ST};
`;



export default function PanierST({className}) {
const basket = useSelector(state => state.basket);
const extractRelevantProperties = (basket) => {
    if (!basket?.items) {
      return [];
    }
    return basket.items.map(item => ({
      IDArticle: item.IDArticle,
      Prix: item.Prix,
      PrixVDontEchoTaxe: item.PrixVDontEchoTaxe,
      PromoWeb: item.PromoWeb,
      PromoMois: item.PromoMois,
      quantity: item.quantity,
      idPlanif: item.planifId,
    }));
  };
const relevantBasketData = extractRelevantProperties(basket);
const user = useSelector(state => state.user?.user?.Contact); 
const [quantities, setQuantities] = useState(basket?.items?.map(item => item.quantity));
const mag = useSelector(state => state.magasin);
const [data, setData] = useState();
const [stockData, setStockData] = useState({});
const [stockPlaces, setStockPlaces] = useState({});
const [placesLibres, setPlacesLibres] = useState({});
const idMagasinLS = mag?.magasin?.ID;
const [isLoading, setIsLoading] = useState(false);
const [isErrorVisible, setIsErrorVisible] = useState(false);
const [txt, setTxt] = useState("");
const [showStockMessage, setShowStockMessage] = useState(false);
const [updatedPrices, setUpdatedPrices] = useState({});
const dispatch = useDispatch();

useEffect(() => {
  if (basket?.items?.every(item => item?.mode === "atelier")) {
    console.log("modeLivraison", 0);
    dispatch({
      type: "SET_MODELIVR",
      payload: {
        modeLivraison: 1,
      }
    });
  } else if (basket?.items?.some(item => item?.mode === "article" && mag?.magasin?.ID === "50ec9764-679e-4acf-8945-61a97ffcb8d5")) {
    console.log("modeLivraison", 1);
    dispatch({
      type: "SET_MODELIVR",
      payload: {
        modeLivraison: 1,
      }
    });
  } else if (basket?.items?.some(item => item?.mode === "article" && mag?.magasin?.ID !== "50ec9764-679e-4acf-8945-61a97ffcb8d5")) {
    console.log("modeLivraison", 2, (basket?.items?.some(item => item?.mode === "article" && mag?.ID !== "50ec9764-679e-4acf-8945-61a97ffcb8d5")));
    console.log("mag", mag.ID);
    dispatch({
      type: "SET_MODELIVR",
      payload: {
        modeLivraison: 2,
      }
    });
  }
}, [basket, mag, dispatch]);

useEffect(() => {
    const fetchStockData = async () => {
      setIsLoading(true);
      try {
        const filteredItems = basket.items?.filter(item => item.mode === "article");
        const stockPromises = filteredItems?.map(item =>
          axios.post(`/users/stock`, { IDArticle: item.ID, IDMagasin: idMagasinLS })
            .then(response => ({ id: item.ID, stock: response.data?.EnStock }))
        );
        const stockResults = await Promise.all(stockPromises);
        const stockMap = stockResults.reduce((acc, { id, stock }) => {
          acc[id] = stock;
          return acc;
        }, {});
        setStockData(stockMap);
      } catch (err) {
        console.log("err", err);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchStockData();
  }, [basket, idMagasinLS]);

  useEffect(() => {
    const fetchPrices = async () => {
      setIsLoading(true);
      try {
        const pricePromises = basket.items?.map(item =>
          axios.post('/users/verificationprix', {
            ...(item.mode === "article" ? { IDArticle: item.ID, Mode: 1 } : item.mode === "atelier"? { IDAtelier: item.ID, Mode: 2 } : null)
          }).then(response => ({ id: item.ID, price: response.data?.Prix }))
        );
        const priceResults = await Promise.all(pricePromises);
        const priceMap = priceResults.reduce((acc, { id, price }) => {
          acc[id] = price;
          return acc;
        }, {});
        setUpdatedPrices(priceMap);
      } catch (err) {
        console.log("err", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrices();
  }, [basket]);

  


  useEffect(() => {
    const fetchStockPlaces = async () => {
      setIsLoading(true);
      try {
        const filteredItems = basket.items?.filter(item => item.mode === "atelier");
        const stockMap = filteredItems?.reduce((acc, item) => {
          if (item.planifId === "000" || item.planifId === null) {
            acc[`${item.ID}-000`] = {
              stock: 9999,
              lib: item.lib,
              date: "" 
            };
          } else {
            const planif = item.Planification?.find(planif => planif.ID === item.planifId);
            if (planif) {
              acc[`${item.ID}-${item.planifId}`] = {
                stock: planif.PlaceLibre,
                lib: item.lib,
                date: planif.Date
              };
            }
          }
          return acc;
        }, {});
        setStockPlaces(stockMap);
      } catch (err) {
        console.log("err", err);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchStockPlaces();
  }, [basket]);

  const getOutOfStockItems = () => {
    return basket.items
      .filter((item, index) => 
        item.mode === "article" && 
        (stockData[item.ID] === 0 || stockData[item.ID] < quantities[index])
      )
      .map(item => item.libArticle);
  };
  const outOfStockItems = getOutOfStockItems();
  
  const getOutPlaces = () => {
    return basket.items
      .filter((item, index) => {
        const planifId = item.planifId || "000"; // Default to "000" if planifId is null
        const key = `${item.ID}-${planifId}`;
        const stockEntry = stockPlaces[key];
        return (
          item.mode === "atelier" &&
          (stockEntry?.stock === 0 || stockEntry?.stock < quantities[index])
        );
      })
      .map(item => {
        const planifId = item.planifId || "000"; // Default to "000" if planifId is null
        const planif = item.Planification?.find(planif => planif.ID === planifId);
        const planifDate = planif?.Date;
        return `${item.lib} ${planifDate ? ` - ${planifDate}` : ""}`;
      });
  };
  
  const outPlaces = getOutPlaces();
    
    useEffect(() => {
    setQuantities(basket?.items?.map(item => item.quantity));
  }, [basket]);

  const handleQuantityChange = (e, itemId,dataStock,planifId,min,unite) => {
    let value = e.target.value;
    
    if (value < min) {
      value = min;
    }
    let minimum = unite === 1 ? 1 : unite === 2 ? 0.5 : 1;
    if (value < minimum) {
      value = minimum;
    }
    const newQuantity = value;
    if (newQuantity > dataStock) {
        setShowStockMessage(true);
        }
    if (!isNaN(newQuantity)) {
      setQuantities(prevQuantities => 
        prevQuantities.map((quantity, index) => 
        !basket.items[index].planifId ?  basket.items[index].ID === itemId ? newQuantity : quantity : basket.items[index].ID === itemId && basket.items[index].planifId === planifId ? newQuantity : quantity
)
      );
     setShowStockMessage(false);
      dispatch({
        type: "UPDATE_BASKET",
        payload: {
          id: itemId,
          planifId: planifId ? planifId : null,
          quantity: newQuantity
        }
      });
    }
  };
  const totalEstimatedPrice = basket?.items?.reduce((total, item, index) => {
    const price = item.Prix ?? item?.PUTTC;
        return total + (quantities[index] * price);
    }, 0).toFixed(2);


    const handleDelete = (id, planifId) => {
      console.log("ici",id);
      dispatch({ type: 'DELETE_ARTICLE', payload: { id, planifId } });
    };

    const blockCommande = () => {
        setTxt("Certains produits de votre panier ne sont plus disponibles ou le stock n'est pas suffisant. Veuillez les retirer ou modifier pour continuer.")
        setIsErrorVisible(true);
    }

    const handleContinueShopping = () => {
        navigate(-1);
    };

    
    const validerPanier = () => {
      window.location.href = "/commande";
    };

    return (
        <PanierWrap className={className}>
            {isErrorVisible && <PopErrorST  classNameBut={"white"} message={txt} titre={"Votre Panier"} onClick={() => setIsErrorVisible(false)}/>}
            {basket?.items?.length>0 ? (
                <>
                <h2 className="center homemadeTitle initial green">Votre panier</h2>
                    {outOfStockItems.length > 0 ? (
                    <Message>
                        <ul className='white bold center'>Attention! Certains produits qui se trouvent dans votre panier ne sont plus disponibles ou le stock n'est pas suffisant :</ul>
                        
                        {outOfStockItems.map((item, index) => (
                            <li className='white center' key={index}>{item}</li>
                        ))}
                        
                    </Message>
                    ) : 
                    null} 
                    {outPlaces.length > 0 ? (
                    <Message>
                        <ul className='white bold center'>Attention! Certains ateliers qui se trouvent dans votre panier ne sont plus disponibles ou le nombre de places n'est pas suffisant :</ul>
                        
                        {outPlaces.map((item, index) => (
                            <li className='white center' key={index}>{item}</li>
                        ))}
                        
                    </Message>
                    ) : 
                    null}

                <Card bordered={false} style={{ padding: className === "mob" ? 0 : 20 }}>
                    <Card.Grid className="pc" hoverable={false} style={{width:"60%"}}>
                    <p className="mb0 uppercase">Produit</p>
                    </Card.Grid>

                    <Card.Grid className="pc"  hoverable={false} style={{width:"25%"}}>
                    <p className="mb0 uppercase">Quantité</p>
                    </Card.Grid>

                    <Card.Grid className="pc" hoverable={false} style={{width:"15%",textAlign:"right"}}>
                    <p className="mb0 uppercase right">Total</p>
                    </Card.Grid>

                    {basket.items.map((item, index) => {
                    const matchedPlanif = item?.Planification?.find(planif => planif?.ID === item?.planifId);
                    let maxQuantity = matchedPlanif ? matchedPlanif?.PlaceLibre : undefined;
                    if (!maxQuantity) {maxQuantity = 9999;}
                    return (
                        <>
                            {item?.mode === "article" ? (
                                <>
                                    <CardGridStyled className={`nom ${className}`}>
                                        {stockData[item.ID] > 0 ? (
                                            stockData[item.ID] < quantities[index] ? (
                                                <Stock title="Stock limité"><BadgeStyled status="warning" /></Stock>
                                            ) : (
                                                <Stock title="En stock"><BadgeStyled status="success" /></Stock>
                                            )
                                        ) : (
                                            <Stock title="En rupture"><BadgeStyled status="error" /></Stock>
                                        )}
                                        <Produit>
                                            <Img src={item?.Photos[0]?.image?.url ? item?.Photos[0]?.image?.url : picDefault} alt={item?.Photos[0]?.image?.alt} onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = picDefault;
                                            }}/>
                                            <Txt className={className}>
                                                <LienNoirST lib={item?.libArticle} href={item?.slug} />
                                                <span>€{updatedPrices[item?.ID] ? updatedPrices[item?.ID] : item?.Prix}</span>
                                            </Txt>
                                        </Produit>
                                    </CardGridStyled>
                
                                    <CardGridStyled className={`quantite ${className}`} hoverable={false}>
                                        <Quantity>
                                            <FormGroupStyled>
                                                {(stockData[item.ID] !== 0 && quantities[index]>stockData[item.ID]) ? (<Message2 className={className}>Maximum : {stockData[item.ID]}</Message2>) : null}
                                                <InputStyled
                                                    id={`quantity-${index}`}
                                                    name="quantity"
                                                    placeholder="0"
                                                    type="number"
                                                    min={item?.Unite === 1 ? "1" : item?.Unite === 2 ? "0.1" : "1"}
                                                    step={item?.Unite === 1 ? "1" : item?.Unite === 2 ? "0.1" : "1"}
                                                    value={quantities[index]}
                                                    onChange={(e) => handleQuantityChange(e, item.ID,stockData[item.ID],"","",item?.Unite)}
                                                    onBlur={(e) => {
                                                      let value = parseFloat(e.target.value);
                                                      if (!isNaN(value)) {
                                                        if (item?.Unite === 1) {
                                                          value = Math.round(value); // No decimals
                                                        } else if (item?.Unite === 2) {
                                                          value = value.toFixed(1); // One decimal place
                                                        }
                                                        handleQuantityChange({ ...e, target: { ...e.target, value } },item.ID,stockData[item.ID],"","",item?.Unite);
                                                      }
                                                    }}
                                                />
                                            </FormGroupStyled>
                                            <FaTrashAlt className="trash" onClick={() => handleDelete(item.ID)} />
                                        </Quantity>
                                    </CardGridStyled>
                
                                    <CardGridStyled className={`prix ${className}`} hoverable={false}>
                                        <Prix className={["fs20", className]}>€{(quantities[index] * (updatedPrices[item?.ID] ? updatedPrices[item?.ID] : item?.Prix)).toFixed(2)}</Prix>
                                    </CardGridStyled>
                                </>
                            ) : item?.mode === "atelier" ? (
                                <>
                                <CardGridStyled className={`nom ${className}`}>
                                    {(() => {
                                        const planifId = item.planifId || "000"; // Default to "000" if planifId is null
                                        const key = `${item.ID}-${planifId}`;
                                        const stockEntry = stockPlaces[key];

                                        if (stockEntry?.stock > 0) {
                                            if (stockEntry.stock < quantities[index]) {
                                            return <Stock title="Places limités"><BadgeStyled status="warning" /></Stock>;
                                            } else {
                                            return <Stock title="Places disponibles"><BadgeStyled status="success" /></Stock>;
                                            }
                                        } else {
                                            <Stock title="Places indisponibles"><BadgeStyled status="error" /></Stock>
                                            return null;
                                        }
                                    })()}
                                    <Produit>
                                        <Img src={item?.img?.url ? item?.img?.url : picDefault} alt={item?.img?.alt} onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = picDefault;
                                          }}/>
                                        <Txt className={className}>
                                            <LienNoirST lib={item?.lib} href={item?.slug} />
                                            <span>€{updatedPrices[item?.ID] ? updatedPrices[item?.ID] : item?.PUTTC}</span>
                                            {matchedPlanif && 
                                            <Planif>
                                                {matchedPlanif.Date} à {matchedPlanif.Heure} ({matchedPlanif.Lieu})
                                            </Planif>}
                                        </Txt>
                                    </Produit>
                                </CardGridStyled>
            
                                <CardGridStyled className={`quantite ${className}`} hoverable={false}>
                                    <Quantity>
                                        <FormGroupStyled>
                                            <InputStyled
                                                id={`quantity-${item.ID}-${item.planifId}`}
                                                name="quantity"
                                                placeholder=""
                                                type="number"
                                                // min="1"
                                                min={item?.CommandeMinimum}
                                                step={item?.CommandeFormatDecimale === 1 ? "1" : item?.CommandeFormatDecimale === 2 ? "0.1" : item?.CommandeFormatDecimale === 3 ? "0.01" : "1"}
                                                value={quantities[index]}
                                                // max={item.Planification ? item.Planification.find(planif => planif.ID === item.planifId)?.PlaceLibre : undefined}
                                                onChange={(e) => handleQuantityChange(e, item.ID,"", item?.planifId,item?.CommandeMinimum,"")}
                                                onBlur={(e) => {
                                                  let value = parseFloat(e.target.value);
                                                  if (!isNaN(value)) {
                                                      value = Math.round(value); // No decimals
                                                    } 
                                                    handleQuantityChange({ ...e, target: { ...e.target, value } }, item.ID,"", item?.planifId,item?.CommandeMinimum,"");
                                                  }
                                                }
                                            />
                                              {maxQuantity !== undefined && quantities[index]> maxQuantity && (
                                                <Message2 className={className}>Maximum : {maxQuantity}</Message2>
                                                )}
                                            {/* {item.Planification && (
                                            <span>
                                            {item.Planification.find(planif => planif.ID === item.planifId)?.PlaceLibre || 'N/A'}
                                            </span>
                                            )} */}
                                        </FormGroupStyled>
                                        <FaTrashAlt className="trash" onClick={() => handleDelete(item?.ID, item?.planifId)} />
                                    </Quantity>
                                </CardGridStyled>
            
                                <CardGridStyled className={`prix ${className}`} hoverable={false}>
                                    <Prix className={["fs20", className]}>€{(quantities[index] * (updatedPrices[item?.ID] ? updatedPrices[item?.ID] : item?.PUTTC)).toFixed(2)}</Prix>
                                </CardGridStyled>
                            </>
                            ) : null}
                        </>
                    );
                })}

                <Total className={className}>
                    <Prix className="fs16">Total estimé : &nbsp;<span> €{totalEstimatedPrice} EUR </span></Prix>
                </Total>
                {(outOfStockItems.length > 0 || outPlaces.length > 0) ? (<BoutonNoirBtnST lib={"Valider votre panier"} onClick={blockCommande}/>) :
                (<BoutonNoirBtnST lib={"Valider votre panier"} onClick={validerPanier}/>)}
            </Card>
            </>) : ( 
            <NoProducts>
                <h2 className="center homemadeTitle initial green">Votre panier est vide</h2>
                <BoutonNoirBtnST lib={"Continuer les achats"} onClick={handleContinueShopping}/>
                {!user && 
                <><h3>Vous possédez un compte ?</h3>
                <p><LienNoirST lib={"Connectez-vous"} href={"/membre"}/> pour payer plus vite.</p></>}
            </NoProducts>
            )}
        </PanierWrap>
  );
}
