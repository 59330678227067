import React, {useState, useEffect} from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import styled from "styled-components";
import  {FormGroup ,Label} from "reactstrap";
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonNoirST from "../DesignSystem/SelfTissus/BoutonNoirBtn";
import LienNoirBtnST from "../DesignSystem/SelfTissus/LienNoirBtn";
import { useSelector, useDispatch } from "react-redux";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import FormOubliPassST from "./FormOubliPass";
import {PopUpContainer, ButFermer} from "../DesignSystem/PopUpST";
import {IoCloseCircleOutline} from "react-icons/io5";
import FormInscriptionST from "./FormInscription";
import {FadeStyled } from "../DesignSystem/Commons/Containers";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Wrap = styled.div`
display: flex; 
flex-direction: column; 
gap: 20px; 
align-items: center; 
justify-content: center;
width: 100%;
  padding: 20px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
  }
`;

const Txt = styled.div`
color: ${props => props.theme.beigeClair}; 
text-align: left; font: 500 20px Unbounded, sans-serif; position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    text-align:center;
  }
`;

const FormStyled = styled(FormGroup)`
position: relative;
display: flex;
width: 100%;
max-width: 600px;
`

const Eye = styled.div`
position: absolute;
transform: translateY(-50%);
right: 10px;
top: 50%;
&>svg {
  font-size: 20px;
  color: ${props => props.theme.greenST};
}
`


export default function FormConnexionST() {
  const [isLoading, setIsLoading] = useState(false);
  const [Login, setLogin] = useState("");
  const [MDP, setMDP] = useState("");
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [data, setData] = useState({});
  const [picture, setPicture] = useState('');
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user?.user?.Contact);
  const [open, setOpen] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmitConnection = (e) => {
    setIsLoading(true);
     axios.post("/users/connexion", {"Login":Login , "MDP":MDP}, {headers: {"Content-Type": "application/json"}})   
     .then(res => {
         localStorage.setItem("token", res.data.token);
         dispatch({type: "SET_USER", payload: res.data
        });
         setIsLoading(false);
         setIsErrorVisible(true);
         setTxt("Vous êtes connecté");
     })
     .catch(err => {
         console.log("err", err);
         setIsLoading(false);
         setTxt(err?.response?.data);
         setIsErrorVisible(true);
         dispatch({type: "DELETE_USER"})
     })
 } 
 
 const handleOnClose = () => {
  setShowForm(false);
  }

    return (
      <Wrap>
        {isLoading && <SpinnerST />}
        {isErrorVisible && <PopErrorST message={txt} titre={"Connexion"} onClick={() => setIsErrorVisible(false)}/>}
        {showForm === true ?
        ReactDOM.createPortal(
          <PopUpContainer className="form">
            <FormOubliPassST onClose={handleOnClose}/>
          </PopUpContainer>,
                  document.body
        ) : ""}
            
          <>
          {
            user && user.ID !== 0 ? (
              <Txt>
                <h3 className="linesGreen" style={{margin:"0"}}>Vous êtes connecté</h3>
              </Txt>
            ) : (
            <>
              <Txt>
                <h2 className="center homemadeTitle initial green">Connexion</h2>
              </Txt>
              <FormStyled floating>
                <InputStyled
                  id="login"
                  name="login"
                  placeholder="Login"
                  type="text"
                  value={Login}
                  onChange={(e) => setLogin(e.target.value)}
                />
                <Label for="email">Login</Label>
              </FormStyled>

              <FormStyled floating>
                <InputStyled
                  id="password"
                  name="mdp"
                  placeholder="Mot de passe"
                  type={passwordVisible ? 'text' : 'password'}
                  value={MDP}
                  onChange={(e) => setMDP(e.target.value)}
                />
                <Eye onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </Eye>
                <Label for="password">Mot de passe</Label>
              </FormStyled>

              <LienNoirBtnST lib={"Mot de passe oublié ?"} onClick={() => setShowForm(true)} />
              <BoutonNoirST lib={"Connexion"} onClick={handleSubmitConnection} />
              <LienNoirBtnST lib={"Créer un compte"} onClick={() => setOpen(!open)}/>
               <FadeStyled className="noPadding" in={open}>
                <FormInscriptionST/>
              </FadeStyled>
            </>
            )
          }
         
          
        </>
      </Wrap>
  );
}
