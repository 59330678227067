import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {FaSearch} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";

const BtnJaune = styled.a`
text-decoration: none;
text-align: center;
background: ${props => props.theme.jaune01ST};
display: inline-block; 
align-items: center; 
justify-content: center;
align-items: center;
padding: 10px 20px;
width: ${props => props.width || "auto"};
margin: ${props => props.margin || "0"};
position: relative;
cursor: pointer;
color: #fff; 
font-size: 17px;
font-weight: 600;
transition: all .2s ease-out;
  &:hover {
    opacity: 0.7;
    text-decoration: none;
    color: #fff;
}

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonJauneST(props) {
  const handleClick = () => {
    if (props.onClick) {
        props.onClick();
    }
    if (props.url) {
        window.location.href = props.url;
    }
};

    return <BtnJaune className={props.className} margin={props.margin} width={props.width} widthMob={props.widthMob} onClick={handleClick}>{props.lib}</BtnJaune>   


}