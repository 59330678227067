import React from "react";
import { useState, CSSProperties } from "react";
import ReactDOM from 'react-dom';
import styled, { useTheme } from "styled-components";
import { ColorRing, Oval,RotatingTriangles } from 'react-loader-spinner'
import {PopUpContainer} from "../PopUpST";

const DivLoading = styled.div`
position: absolute;
transform: translate(-50%, -50%);
top: 120px;
left: 41%;
display: flex;
flex-direction: column;
align-items: center;
background: #ffffff;
border-radius: 10px;
padding: 10px;
width: 300px;
margin: auto;
z-index: 1;
box-shadow: 0 0 4px 5px #29262591;
&> span {
  color: ${props => props.theme.greenST};
}

@media screen and (max-width: 768px) {
  width: 95%;
  max-width: 100%;
}
`;

const Titre = styled.div`
  font-family: "Assistant", sans-serif;
  font-size: 20px;
  text-align: center;
  padding: 10px 0;
  color: ${props => props.theme.grey03ST};
  `
const SpanPat = styled.span`
padding: 10px 0;
`


  export default function SpinnerST() {
    const theme = useTheme();
    const color1 = theme.greenST;
    const color3 = theme.redST;
    const color4 = theme.jaune01ST;

  return (
    <DivLoading className="sweet-loading">
      <Titre>Traitement en cours ...</Titre>
      <RotatingTriangles
        visible={true}
        height="80"
        width="80"
        colors={[color3, color1, color4]}
        ariaLabel="rotating-triangles-loading"
      />
      <SpanPat>Veuillez patienter</SpanPat>
      </DivLoading>
  );
}
