// Import any necessary action types
// import { SET_USER, UPDATE_USER, DELETE_USER } from '../actions/types';

// Define the initial state
const initialState = {
    heure: null,
};

const heureReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_HEURE":
            return {
                ...state,
                heure: action.payload,
            };

        case "DELETE_HEURE":
            return {
                ...state,
                heure: null,
            };
        default:
            return state;
    }
};

export default heureReducer;