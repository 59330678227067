import React from "react";
import styled from "styled-components";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import BoutonLienST from "../DesignSystem/SelfTissus/BoutonLien";

const Boutons = styled.div`
display: flex;
flex-direction: row;
gap: 10px;
justify-content: center;
align-items: center;
flex-wrap: wrap;
width: 100%;
`;

const Wrap = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;
padding-top: 20px;
`;


export default function MenuBoutonsST({data}) {
    return (
        <Wrap>
        <h1>{data?.Titre}</h1>
        <div>{data?.Corps}</div>
        <Boutons>
            {data && data?.Data?.map((item, index) => (
                <BoutonLienST key={index} background={"#ffce00"} width={"300px"} margin={"0"} lib={item?.BTNLib} href={item?.BTNUrl}/>
            ))
            }
        </Boutons>
        </Wrap>
    );
    }
