import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {FaSearch} from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import {FaChevronDown} from 'react-icons/fa'
import {FaChevronUp} from 'react-icons/fa'

const Btn = styled.a`
text-decoration: none;
text-align: center;
text-transform: uppercase;
border: none;
background: ${props => props.background || "#fff"};
padding: 18px;
width: ${props => props.width || "auto"};
margin: ${props => props.margin || "auto"};
position: relative;
cursor: pointer;
color: #fff;
font-weight: 600;
font-size: 15px;
font-family: "Assistant", sans-serif;
transition: all .2s ease-out;
  &:hover {
    opacity: 0.8;
    color: #fff;
}
&>svg {
    display: none;
}
&.chevron.open {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &>svg {
        display: inline-block;
    }
}
&.selected {
    background: ${props => props.theme.orangeST};
    color: #fff;
    scale: 1.1;
}
&.small {
    padding: 12px;
    font-size: 13px;
    text-transform: initial;
}
&.medium {
    padding: 14px;
    font-size: 16px;
    text-transform: initial;
}

&.block {
    display: block;
}

&.mobOnly {
    display: none;
}
@media screen and (max-width: 768px) {
    &.mobOnly {
    display: block;
}
}

@media screen and (max-width: 576px) {
    width: ${props => props.widthMob || "100%"};
    padding: 15px;
    margin: auto;
}
`;


export default function BoutonLienST(props) {
    return <Btn 
    className={props.className} 
    margin={props.margin} 
    width={props.width} 
    widthMob={props.widthMob} 
    href={props.href}
    background={props.background}
    >
    {props.lib}<FaChevronUp/>
    </Btn>   


}