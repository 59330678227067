import React from "react";
import styled from "styled-components";
import {FaPinterest ,FaFacebookF,FaYoutube,FaInstagram} from "react-icons/fa";
import AnneeEnCours from "../ContenusST/AnneeEnCours";

const StyledDiv = styled.div`
background: #fff;
display: flex; 
flex-direction: row; 
align-items: center; 
justify-content: center; 
flex-shrink: 0; 
width: 100%; 
position: relative; 
overflow: hidden;
flex-wrap: wrap;
  @media screen and (max-width: 992px) {
   gap: 0;
   }
`;

const StyledDiv_2 = styled.div`
padding: 45px 10px 0px 10px;
width: 100%;
display: flex;
gap: 10px; 
align-items: center; 
justify-content: space-between;
flex-wrap: wrap; 
position: relative; 
overflow: hidden;
   @media screen and (max-width: 992px) {
   flex-direction: column;
   justify-content: center;
   gap:20px
   } 
   @media screen and (max-width: 576px) {
   gap:10px
   }
`;

const StyledDiv_3 = styled.div`
color: ${props => props.theme.redST}; 
text-align: left; 
  @media screen and (max-width: 576px) {
    text-align: center;
    font-size: 14px;
  }
`;

const StyledDiv_4 = styled.div`
  box-sizing: border-box;  display: flex; flex-direction: row; gap: 20px; align-items: center; justify-content: center; flex-shrink: 0; position: relative;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const StyledDiv_5 = styled.div`
  box-sizing: border-box;  color: rgb(255, 255, 255); text-align: left; font: 400 16px / 25px Unbounded, sans-serif; position: relative;
  @media screen and (max-width: 992px) {

  }
`;

const Socials = styled.div`
  box-sizing: border-box;  padding: 10px; display: flex; flex-direction: row; gap: 22px; align-items: center; justify-content: center; flex-shrink: 0; position: relative; overflow: hidden;

`;

const StyledLink = styled.a`
 position: relative; overflow: hidden;cursor: pointer;
  &>svg{
    color:${props => props.theme.redST};
    width: 36px;
    height: 36px;
  }
  &:hover{
    &>svg{
      opacity: 0.8;
    }
  }
`;



export default function Footer4ST({}) {
  return (
    <>
      <StyledDiv>
        <StyledDiv_2>
          <StyledDiv_3>Copyright © 2002-<AnneeEnCours/></StyledDiv_3>
          <StyledDiv_4>
            <Socials>
            <StyledLink href="https://www.facebook.com/SelfTissus" target="_blank">
              <FaFacebookF/>
              </StyledLink>
              <StyledLink href="https://www.pinterest.fr/selftissusfr/" target="_blank">
              <FaPinterest />
              </StyledLink>
              <StyledLink href="https://www.instagram.com/self_tissus/" target="_blank">
                <FaInstagram/>
              </StyledLink>
              <StyledLink href="https://www.youtube.com/@selftissusfrance" target="_blank">
                <FaYoutube/>
              </StyledLink> 

             
              </Socials>
          </StyledDiv_4>
         
        </StyledDiv_2>

      </StyledDiv>
    </>
  );
}
