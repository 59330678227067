import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
width: 100%;
padding: 50px 20px 0px 20px;
&.mob {
    padding: 0;
}
  @media only screen and (max-width: 992px) {
    width: 100%;
    gap: 44px;
    padding: 0;
  }
`;


export default function PanierPaiementInvalideST({data}) {

    return (
        <Wrap>
          <h2 className="center">{data?.Titre}</h2>
          <p className="center orange">{data?.Corps}</p>
        </Wrap>
  );
}
