import React from "react";
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const Txt = styled.div`
   padding: ${props => props.padding ? props.padding : "0 50px"};
   @media screen and (max-width: 786px) {
    padding: 0 20px;
   }
`;



const Bloc = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 0;
justify-content: flex-start;
@media screen and (max-width: 992px) {
    flex-wrap: wrap;
}
`;



export default function TitreH2TextST({data,padding}) {
    return (
            <Bloc>
                <Txt padding={padding}>
                    {data?.Titre && data?.Titre !=="WebComposant" ? <h2>{ReactHtmlParser(data?.Titre)}</h2> : null}
                    <p>{ReactHtmlParser(data?.Corps)}</p>
                </Txt>
            </Bloc>
    )
}