import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { FormGroup, Label } from "reactstrap";
import ReactHtmlParser from 'react-html-parser';
import { InputStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonBlancBtnST from "../DesignSystem/SelfTissus/BoutonBlancBtn";
import BoutonFavouriteST from "../DesignSystem/SelfTissus/BoutonFavourite";
// import img from "../../assets/images/consigne-tri_213x.avif";
import {Badge} from 'antd';
import {WrapFormPop} from "../DesignSystem/PopUpST";
import PopPanierST from "../PopupsST/PopPanier";
import { AiOutlineEnvironment } from "react-icons/ai";
import { MdOutlineLocalShipping } from "react-icons/md";
import LienNoirBtnUnderlineST from "../DesignSystem/SelfTissus/LienNoirBtnUnderline";
import PopLocalisationST from "../PopupsST/PopLocalisation";
import PopErrorST from "../PopupsST/PopError";
import SpinnerInlineST from "../DesignSystem/SpinnersST/SpinnerInline";
import FavoriHeartST from "../Favoris/FavoriHeart";
import ShareST from "../Share/ShareST";
import picDefault2 from "../../assets/images/photo2.png";
import { FaFilePdf } from "react-icons/fa";
import { PiDotsThreeOutline } from "react-icons/pi";

const Wrap = styled.div`
padding-left: 40px;
/* width: 80%;
min-width: 350px; */
@media screen and (max-width: 786px) {
    padding-left: 0;
}
`;

// const StyledList = styled.ul`
//     display: flex;
//     justify-content: flex-start;
//     gap: 5px;
//     flex-wrap: wrap;
//     padding: 0;
//     &>li{
//       margin: 0 0px 0px 30px;
//     }
// `;

const StyledList = styled.ul`
width: 330px;
margin: 40px 0 20px 0;
list-style-type: none;
padding: 0;
display: flex;
flex-direction: column;
@media screen and (max-width: 786px) {
  width: 100%;
}
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 7px;
  &.column {
    flex-direction: column;
    gap: 20px;
    &>div {
    gap: 20px;
    display: flex;
    flex-direction: column;
    }
    &>div>div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &:nth-child(odd) {
    background-color: #ffcc0028;
  }
  &:nth-child(even) {
    background-color: #79b69f4b;
  }
`;

const Label1 = styled.b`
  flex: 1;
`;

const Value = styled.div`
  flex: 1;
  text-align: right;
  &>span {
    display: block;
  }
`;

const Color = styled.div`
display: flex;
gap: 10px;
margin: 20px 0;
flex-wrap: wrap;
`;
const Small = styled.img`
cursor: pointer;
width: 40px;
height: 40px;
border-radius: 50%;
transition: all 0.3s;
&.selected {
  border: 3px solid ${(props) => props.theme.orangeST};
}
&:hover {
  border: 3px solid ${(props) => props.theme.orangeST};
}
`;

const Prices = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 18px;
gap: 20px;
`
const Old = styled.span`
text-decoration: line-through solid 2px ${(props) => props.theme.redST};
`
const New = styled.span`
font-weight: 700;
font-size: 28px;
&>span {
  margin-left: 5px;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  color: ${(props) => props.theme.redST};
}
`
const TxtSmall = styled.div`
color: ${(props) => props.theme.grey09ST};
font-size: 14px;
`;

const BadgeStyled = styled(Badge)`
  .ant-badge-status-dot {
    width: 12px !important;
    height: 12px !important;
    margin-right: 5px;
  }
  `;


const Buts = styled.div`
display: flex;
gap: 20px;
flex-direction: column;
justify-content: flex-start;
align-content: flex-start;
flex-wrap: wrap;
`;
const Quantity = styled.div`
position: relative;
padding: 20px 0;
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
gap: 20px;
`;

const Prix = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
font-size: 20px;
font-weight: 700;
span {
  color: ${(props) => props.theme.redST};
  font-size: 24px;
}
`;

const Details = styled.div`
padding: 20px 0;
&>* {
  color: ${props => props.theme.grey06ST}; 
}
`;

const Livraison = styled.div`
display: flex;
gap: 40px;
flex-wrap: wrap;
`;
const Livr = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: space-between;
gap: 10px;
padding: 10px;
border: solid 1px ${(props) => props.theme.grey01ST};
box-shadow: 0 0 5px rgba(0,0,0,0.1);
min-width: 300px;
&>.fs14 {
  font-size: 14px;
}
&>.in {
  display: flex;
  align-items: center;
  gap: 10px;
  &>svg {
    height: 25px;
    width: 25px;
  }
}
@media screen and (max-width: 786px) {
  min-width: 100%;
}
`;
const Stock = styled.div`
font-size: 14px;
`;

const Exp = styled.div`
font-size: 12px;
`;

const Message = styled.span`
position: absolute;
bottom: 10px;
color: ${(props) => props.theme.orangeST};
font-size: 12px;
`;

const Decl = styled.div`
margin-bottom: 20px;
color: ${(props) => props.theme.greenST};
font-style: italic;
font-size: 16px;
`;

const Projets = styled.div`
display: flex;
gap: 10px;
flex-wrap: wrap;
align-items: center;
margin: 20px 0;
&>span {
  display: block;
  width: 100%;
  color: ${(props) => props.theme.grey06ST};
  font-size: 16px;
  font-weight: 700;
}
`;

const Projet = styled.div`
border-radius: 50%;
width: 30px;
height: 30px;
&>a {
  width: 100%;
  height: 100%;
  &>img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
}
&:hover {
  &>a>img {
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }
}
`;

const Plus = styled.div`
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
border-radius: 50%;
box-shadow: 0 0 5px rgba(0,0,0,0.1);
height: 30px;
width: 30px;
&>svg {
  color: ${(props) => props.theme.orangeST};
  transition: all 0.3s;
}
&:hover {
  background-color: #79b69f87;
  &>svg {
    color: #fff;
  }
}
`;

const Btn = styled.button`
background: none;
border: none;
cursor: pointer;
padding: 0;
&> svg {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}
`;

const Entretien = styled.div`
padding-top: 20px;
display: flex;
flex-wrap: wrap;
gap: 10px;
align-items: center;
margin: 20px 0;
&>span {
  display: block;
  width: 100%;
  color: ${(props) => props.theme.grey06ST};
  font-size: 16px;
  font-weight: 700;
}
`;

const Entretien1 = styled.div`
/* width: 100px;
height: 100px; */
&>img {
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
}
`;

const SubTitle = styled.div`
color: ${(props) => props.theme.grey06ST};
font-size: 16px;
font-weight: 700;
margin: 10px 0;
`;

const ImgLabel = styled.img`
width: 60px;
height: 60px;
object-fit: cover;
border-radius: 50%;
box-shadow: 0 0 10px 0 ${props => props.theme.grey01ST};
`;

export default function ArticleDescST({data}) {
  console.log("dataArt", data);
  const [dlPatienter, setDlPatienter] = useState(false);
  const dispatch = useDispatch();
  const basket = useSelector(state => state.basket);
  const favoris = useSelector(state => state.favoris);
  const mag = useSelector(state => state.magasin);
  const [isFav, setIsFav] = useState(false);
  const idMagasinLS = mag?.magasin?.ID;
  const [quantity, setQuantity] = useState(data?.Unite === 1 ? 1 : data?.Unite === 2 ? 0.5 : 1);
  const unitPrice = data?.Prix; 
  const [selected, setSelected] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [changeMag, setChangeMag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataStock, setDataStock] = useState([]);
  const [dataStockWeb, setDataStockWeb] = useState([]);
  const [dataMagNom, setDataMagNom] = useState("");
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState("");
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [showStockMessage, setShowStockMessage] = useState(false);
  const existingItem = favoris.items.find(item => item.ID === data.ID);
  const parsedQuantity = parseFloat(quantity);
  const [visibleProjects, setVisibleProjects] = useState(10);
  const found = !!existingItem;
  const firstPhotoArt = data?.image && data?.image?.url ?  "https://www.selftissus.fr/"+data?.image?.url : picDefault2;

  const handleVoirPlus = () => {
    setVisibleProjects((prev) => prev + 5); 
  };

  useEffect(() => {
    setIsFav(found);
  }, [found]);

  const handleSelect = (id) => {
    setSelected(prev => prev === id ? null : id);
  };

  const handleQuantityChange = (e) => {
    let minimum = data.Unite === 1 ? 1 : data.Unite === 2 ? 0.5 : 1;
    let newQuantity = e.target.value;
    if (newQuantity < minimum) {
      newQuantity = minimum;
    }
        
    if (newQuantity > dataStock) {
      setShowStockMessage(true);
      return;
    }
    setShowStockMessage(false);
    setQuantity(newQuantity);
  };

  const totalPrice = (quantity * unitPrice).toFixed(2);

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/stock`, { IDArticle: data?.ID, IDMagasin: idMagasinLS })
      .then((response) => {
        setIsLoading(false);
        setDataStock(response.data?.EnStock);
        setDataMagNom(response.data?.IDMagasin === "50ec9764-679e-4acf-8945-61a97ffcb8d5" || response.data?.IDMagasin === "977f6921-ed0a-44e7-9425-742d52bd62ea" ? '' : response.data?.Magasin);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  }, [changeMag,idMagasinLS]);

  useEffect(() => {
    setIsLoading(true);
    axios.post(`/users/stock`, { IDArticle: data?.ID, IDMagasin: "50ec9764-679e-4acf-8945-61a97ffcb8d5" })
      .then((response) => {
        setIsLoading(false);
        setDataStockWeb(response.data?.EnStock);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  }, []);

  const choixLivrDomicile = () => {
    dispatch({
      type: "SET_MAGASIN",
      payload: {
        ID: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
        Nom: "WEB"
      }
    });
    dispatch({
      type: "SET_MODELIVR",
      payload: {
        modeLivraison: 1
      }
    });
    // dispatch({
    //   type: "SET_ADRESSE",
    //   payload: {
    //     IDAdresse: "50ec9764-679e-4acf-8945-61a97ffcb8d5",
    //   },
    // });
    dispatch({
      type: "DELETE_ADRESSE",
    });
    setTxt("Votre choix a bien été pris en compte");
    setIsErrorVisible(true);
  };

  const panierAjout = (article) => {
    const existingItem = basket.items.find(item => item.ID === article.ID);
    if (existingItem) {
      dispatch({
        type: "UPDATE_BASKET",
        payload: {
          id: article.ID,
          quantity: parseFloat(existingItem.quantity) + parsedQuantity
        }
      });
    } else {
      dispatch({
        type: "ADD_TO_BASKET",
        payload: { ...article, quantity: parsedQuantity, mode: "article" },
      });
    }
    setShowForm(true);
    setTriggerEffect(true);
  };

  const handleFavouriteChange = (newFavouriteState,article) => {
    setIsFav(newFavouriteState);
    favorisAjout(article,newFavouriteState);
  };

  const favorisAjout = (article,isFavF) => {
    setIsFav(isFavF)
    if (!existingItem) {
      dispatch({
        type: "ADD_TO_FAVORIS",
        payload: { ...article, quantity: 1, mode : "article" },
      });
    }  else {
      dispatch({
        type: "DELETE_FAVORIS",
        payload: article.ID,
      });
    }
  }

  const viewDocument = async (event, token) => {
    console.log("token", token);  
    event.preventDefault();
    event.stopPropagation();
    try {
      const response = await axios.post('/users/download/', { "TokenDL": token });
      const base64String = response.data.Data64;
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
  
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Failed to view the document:', error);
    }
  };

  const handleDownload = async (event, token, num) => {
    event.preventDefault();
    event.stopPropagation();
    setDlPatienter(true);
    try {
      const response = await axios.post(`/users/download/`,{ "TokenDL":token});
      const base64String = response.data.Data64;
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
  
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${num}.pdf`); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setDlPatienter(false);
    } catch (error) {
      console.error('Failed to download the file:', error);
      setDlPatienter(false);
    }
  };

  return (
  <>
  {isErrorVisible && <PopErrorST message={txt} titre={"Magasin SELF TISSUS"} onClick={() => setIsErrorVisible(false)}/>}
  <FavoriHeartST isFav={isFav} onClick={() => handleFavouriteChange(!isFav,data)}/>
  <ShareST shareUrl={window.location.href} 
  title="Je vous partage un article que j'ai trouvé sur le site Self Tissus : " 
  media={firstPhotoArt}
  />
   <Wrap className="desc">
    <h1 className="left">{ReactHtmlParser(data?.libArticle)}</h1>
    <p>Ref : {data?.GenCode}</p>
    {data?.Articles && data?.Articles?.length > 1 ?  (<Decl>{data?.Articles?.length} déclinaisons possibles</Decl>) : ""}
    {data && data?.Articles?.length > 1 && (
      <Color>
        {data?.Articles.map((article, index) => {
          // const firstPhoto = article?.Photos && article?.Photos?.length > 0 ? article?.Photos[0] : null;
          // if (!firstPhoto) return null;

          return (
            <a href={article?.slug} title={article?.libArticle} key={index}>
              <Small key={index}
                id={article?.ID}
                onClick={() => handleSelect(article?.ID)}
                // className={selected === article?.ID ? "selected" : ""}
                className={article?.slug.replace(/\/$/, '') === window.location.pathname.replace(/\/$/, '') ? 'selected' : ''}
                src={article?.image?.url ? article?.image?.url : picDefault2}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = picDefault2;
                  }} loading="lazy" 
                  alt={article?.image?.alt ? article?.image?.alt : article?.libArticle}
              />
            </a>
          );
        })}
      </Color>
    )}

    <Prices>
    {/* <Old>12,50€</Old> */}
    <New>{data?.Prix +"€"}<span>{data?.Unite === 2 ? ' le mètre' : data?.Unite === 1 ? "l'unité" : ''}</span></New>
   
    </Prices>
    <TxtSmall>Taxes incluses.</TxtSmall>
    <hr></hr>
    <Buts>
      <Quantity>
        <FormGroup floating>
          <InputStyled
             id="prix"
             name="number"
             placeholder="Quantité"
             type="number"
             value={quantity}
             onChange={handleQuantityChange}
             onBlur={(e) => {
              let value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                if (data?.Unite === 1) {
                  value = Math.round(value); // No decimals
                } else if (data?.Unite === 2) {
                  value = value.toFixed(1); // One decimal place
                }
                handleQuantityChange({ ...e, target: { ...e.target, value } });
              }
            }}
             min={data?.Unite === 1 ? "1" : data?.Unite === 2 ? "0.5" : "1"}
             step={data?.Unite === 1 ? "1" : data?.Unite === 2 ? "0.1" : "1"}
            // step={data?.CommandeFormatDecimale === 1 ? "1" : data?.CommandeFormatDecimale === 2 ? "0.1" : data?.CommandeFormatDecimale === 3 ? "0.01" : "1"}
             className={showStockMessage ? 'info' : ''}
          />
            <Label for="prix">
            Quantité
            </Label>
          </FormGroup>
          {showStockMessage && <Message>Stock insuffisant</Message>}
          <Prix>  Prix total : <span>{totalPrice} €</span></Prix>
        </Quantity>
        
      <BoutonBlancBtnST className={dataStock === 0 ? "disabled" : ""} lib="Ajouter au panier" margin={"0"} width="100%" onClick={() => panierAjout(data)}/>
      <BoutonFavouriteST isFav={isFav} lib="Pour un futur projet couture" width="100%"  margin={"0"} onClick={() => handleFavouriteChange(!isFav,data)}/>
      <Livraison>
          <Livr>
            {isLoading && <SpinnerInlineST/>}
            <div className="in red fs14"><AiOutlineEnvironment/>Click & Collect</div>
            {dataMagNom ? <div className="in red fs14">{dataMagNom}</div> : <Exp>
            Cliquez pour choisir le magasin<br></br>
            le plus proche de chez vous
            </Exp>}
            {mag?.magasin?.ID !== "50ec9764-679e-4acf-8945-61a97ffcb8d5" &&
              (dataStock > 0 ? (
                <Stock>
                  <BadgeStyled status="success" />En stock
                </Stock>
              ) : (
                <Stock>
                  <BadgeStyled status="error" />En rupture
                </Stock>
              ))
            }
            <LienNoirBtnUnderlineST className="small" margin={"0"} lib="Choisir un magasin"  onClick={() => { setChangeMag(true) }} />
          </Livr>
          <Livr>
            {isLoading && <SpinnerInlineST/>}
            <div className="in green fs16"><MdOutlineLocalShipping/>Livraison à domicile</div>
            {/* <Stock><BadgeStyled status="error"/>En rupture</Stock> */}
            {dataStockWeb>0 ? <Stock><BadgeStyled status="success" />En stock</Stock> : <Stock><BadgeStyled status="error"/>En rupture</Stock>}
            <Exp>
            Expédition en 3 jours ouvrés<br></br>
            Offerte dès 50€ d'achats
            </Exp>
            <LienNoirBtnUnderlineST className="small" margin={"0"} lib="Choisir ce mode de livraison" onClick={choixLivrDomicile}/>
          </Livr>
        </Livraison>
    </Buts>
    {data?.Descriptif && <Details>
      {/* {ReactHtmlParser(data?.Descriptif)} */}
      {/* <ReactMarkdown>{data?.Descriptif}</ReactMarkdown> */}
      {data?.Descriptif && (
        <ReactMarkdown
          children={data?.Descriptif}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
        />
      )}
    </Details>}
    {data?.Projets?.length && <Projets>
    {data?.Projets && data?.Projets?.length > 0 && <span>Pour vos projets couture</span>}
    {data?.Projets && data?.Projets.slice(0, visibleProjects).map((projet, index) => (
        <Projet key={index}>
          <a href={projet?.slug} title={projet?.lib}>
            <img
              src={projet?.image?.url ? projet?.image?.url : picDefault2}
              alt={projet?.image?.alt}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = picDefault2;
              }}
              loading="lazy"
            />
          </a>
        </Projet>
      ))}
      {data?.Projets && visibleProjects < data.Projets.length && (
        <Plus onClick={handleVoirPlus}><PiDotsThreeOutline/></Plus>
      )}
    </Projets>}
    {/* <StyledList>
      {data?.Laize && <li><b>Laize : </b>{data?.Laize} cm</li>}
      {data?.Grammage > 0 ? <li><b>Grammage : </b>{data?.Grammage ? data?.Grammage * 1000 : 0} g/m²</li> : ""}
    </StyledList> */}
    <StyledList>
      {data?.Laize > 0  && (
        <ListItem>
          <Label1>Laize :</Label1>
          <Value>{data?.Laize} cm</Value>
        </ListItem>
      )}
      {data?.Grammage > 0 && (
        <ListItem>
          <Label1>Grammage :</Label1>
          <Value>{data?.Grammage ? data?.Grammage * 1000 : 0} g/m²</Value>
        </ListItem>
      )}
      {data?.TabComposition?.length>0 && (
        <ListItem>
          <Label1>Composition :</Label1>
          <Value>
            {data?.TabComposition.map((comp, index) => (
              <React.Fragment key={comp?.sMatiere}>
                <span key={index}>{comp?.nPourcent.toFixed(0)}% {comp?.sMatiere}{index < data.TabComposition.length - 1 && ', '}</span>
              </React.Fragment>
          ))}
          </Value>
        </ListItem>
      )} 
      {data?.Couleur && ( 
        <ListItem>
          <Label1>Couleur :</Label1>
          <Value>{data?.Couleur}</Value>
        </ListItem>
      )}
        {/* <ListItem>
          <Label1>Rendu :</Label1>
          <Value>{data?.Rendu}</Value>
        </ListItem>
        <ListItem>
          <Label1>Caractéristiques :</Label1>
          <Value>{data?.Catacteristiques}</Value>
        </ListItem> */}
        {data?.TabLabel?.length>0 && (
        <ListItem className="column">
          <Label1>Labels :</Label1>
          <Value>{data?.TabLabel.map((label, index) => (
            <div key={label?.sLabel}>
              <span key={index}>{label?.sLabel}</span>
              <ImgLabel src={label?.jsPictoLabel?.url ? label?.jsPictoLabel?.url : picDefault2}
              alt={label?.jsPictoLabel?.atl} 
              title={label?.jsPictoLabel?.alt}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = picDefault2;
              }}
              loading="lazy"/>
            </div>
          ))}
          </Value>
        </ListItem>
      )} 
    </StyledList>
    {data?.Entretiens?.length && <Entretien>
    {data?.Entretiens && data?.Entretiens?.length > 0 && <span>Entretien</span>}
    {data?.Entretiens && data?.Entretiens?.map((entr, index) => (
        <Entretien1 key={index}>
            <img
            title={entr?.Descriptif ? entr?.Descriptif : "Entretien"}
              src={entr?.image?.url ? entr?.image?.url : picDefault2}
              alt={entr?.image?.alt}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = picDefault2;
              }}
              loading="lazy"
            />
        </Entretien1>
      ))}
    </Entretien>}
    {data?.Entretien && <Entretien>Entretien : <img src={picDefault2} alt="icone d'Entretien"/></Entretien>}
    {data?.Documents && <SubTitle>Documentations : </SubTitle>}
    {data?.Documents
      ? data?.Documents.map((doc, index) => (
          <div style={{position:"relative"}}>
            {dlPatienter && <SpinnerInlineST/>}
          <Btn key={doc?.TokenDL} onClick={(event) => viewDocument(event, doc?.TokenDL)}><FaFilePdf size={40} color={"#ffce00"} title="Voir la documentation"/></Btn>
          <Btn key={doc?.TokenDL} onClick={(event) => handleDownload(event, doc?.TokenDL, doc?.ID)}><FaFilePdf size={40} color={"#ffce00"} title="Télécharger la documentation"/></Btn>
          </div>
        ))
      : ""}
    
      </Wrap>
     {showForm === true ?
      ReactDOM.createPortal(
       <WrapFormPop className="form"><PopPanierST onClick={() => setShowForm(false)} data={data} quantity={quantity} totalPrice={totalPrice}/></WrapFormPop>,
               document.body
     ) : ""}
     {changeMag === true ?
      ReactDOM.createPortal(
       <WrapFormPop className="form">
        <PopLocalisationST onClick={() => setChangeMag(false)}/>
        </WrapFormPop>,
        document.body
     ) : ""}
     </>
  );
}


